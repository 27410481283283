<template>
    <div class="sales">
        <Header />

        <div class="content">

            <h3 class="title-secondary" v-if="!filter">
                {{ getMyLanguage("sales", "sales.title") }}
                <small v-if="nameAgentFilter">{{nameAgentFilter}}</small>
            </h3>

            <div class="box-error" v-if="errorsGet.dataSales != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataSales}}
                </div>
            </div>

            <div v-if="!errorsGet.dataSales">                
                <div class="load" v-if="lengthSales === 'load'"></div>
                
                <div class="search-bar" v-if="lengthSales === true && !filter">
                    <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("sales","sales.search")'>
                    <div class="btn-filter" @click="filter = true"></div>
                    <div class="btn-back" v-if="showBack" onclick="history.back()"></div>
                </div>

                <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                <div class="filter box" v-if="filter">
                    <div class="close-filter" @click="filter = false"></div>
                    
                    <div class="filters">
                        <div class="form-group-switch">
                            <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                            <label for="stage" class="form-switch-label">
                                <small>Filter name</small>
                            </label>
                        </div>
                    </div>

                    <div class="btn-primary">Filter</div>
                </div>

                <div class="box" v-if="lengthSales === true">
                    <div class="scroll-horizontal">
                        <table class="list-result">
                            <thead class="header-list">
                                <tr>
                                    <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                    <th class="fullName">{{ getMyLanguage("list-result","name") }}</th>
                                    <!-- <th class="fullName text-left">{{ getMyLanguage("list-result","agent") }}</th> -->
                                    <th class="campaign">{{ getMyLanguage("list-result","phase") }}</th>
                                    <th class="method">{{ getMyLanguage("list-result","payment") }}</th>
                                    <th class="statusPayment">{{ getMyLanguage("list-result","status") }}</th>
                                    <th class="fiat">{{ getMyLanguage("list-result","salePrice") }}</th>
                                    <th class="fiat">{{ getMyLanguage("list-result","commission") }}</th>
                                </tr>
                            </thead>

                            <tbody id="drop-list">
                                <tr v-for="(saleItem,index) in dataSales" :key="index" @click="viewSale(index)" class="list">
                                    <td class="date"><span>{{dateTime(saleItem.sale.sale_date)}}</span></td>
                                    <td class="fullName"><span>{{saleItem.buyer.profile_name}}</span></td>
                                    <!-- <td class="fullName"><span>{{saleItem.agent.agent_full_name}}</span></td> -->
                                    <td class="campaign"><span>{{ getMyLanguage("status.short", saleItem.campaign.campaign_name) }}</span></td>
                                    <td class="method" :class="saleItem.payment.payment_type"><span>{{ getMyLanguage("status", saleItem.payment.payment_type) }}</span></td>
                                    <td class="statusPayment tooltip" :class="saleItem.payment.payment_status">
                                        <span>{{ getMyLanguage("status", saleItem.payment.payment_status) }}</span>

                                        <div class="tooltip" v-if='saleItem.payment.payment_status == "PaymentConfirmed"'>{{ getMyLanguage("sales","tooltip." + saleItem.payment.payment_status) }} {{dateTime(saleItem.sale.confirm_date)}}</div>
                                        <div class="tooltip" v-if='saleItem.payment.payment_status != "PaymentConfirmed"'>{{ getMyLanguage("sales","tooltip." + saleItem.payment.payment_status) }}</div>
                                    </td>
                                    <td class="fiat"><span>{{saleItem.sale.sale_final_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                    <td class="fiat"><span>{{saleItem.commission.commission_amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="no-results" v-if="lengthSales === false">
                    <p>{{ getMyLanguage("sales", "no-sales") }}</p>
                    <div class="btn-back" onclick="history.back()"></div>
                </div>
            </div>
        </div>
        
        <!-- MODAL -->
        <div class="modal" v-if="modalShow">
            <div @click="modalShow = false" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll-vertical">
                    <h3>{{dataSalesShow.buyer.profile_name}}</h3>

                    <!-- Não há vendas realizadas ainda -->

                    <div class="sale-description" :class="dataSalesShow.status">
                        <div class="fullField"><span>{{ getMyLanguage("modal", "modal.sale-description.id") }}:</span> <strong>#{{dataSalesShow.sale.sale_reference}}</strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("modal", "modal.sale-description.date") }}:</span> <strong v-html="dateTimeFull(dataSalesShow.sale.sale_date)"></strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("modal", "modal.sale-description.payment-method") }}:</span> <strong>{{dataSalesShow.payment.payment_type}}</strong></div>
                        <div class="fullField divider"><span>{{ getMyLanguage("modal", "modal.sale-description.status") }}:</span> <strong class="statusModal" :class="dataSalesShow.payment.payment_status">{{ getMyLanguage("status", dataSalesShow.payment.payment_status) }}</strong></div>

                        <div><span>{{ getMyLanguage("modal", "modal.sale-description.product") }}:</span> <strong>{{dataSalesShow.product.product_name}}</strong></div>
                        <div><span>{{ getMyLanguage("modal", "modal.sale-description.campaign") }}:</span> <strong>{{dataSalesShow.campaign.campaign_name}}</strong></div>
                        <div><span>{{ getMyLanguage("modal", "modal.sale-description.quantity") }}:</span> <strong>{{dataSalesShow.sale.sale_quantity}}</strong></div>
                        <div><span>{{ getMyLanguage("modal", "modal.sale-description.original-price") }}:</span> <strong>{{dataSalesShow.sale.sale_original_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span class="fiat"></span></strong></div>
                        <div><span>{{ getMyLanguage("modal", "modal.sale-description.discount") }}:</span> <strong>{{dataSalesShow.campaign.campaign_discount}}%</strong></div>
                        <div><span>{{ getMyLanguage("modal", "modal.sale-description.bonus") }}:</span> <strong>{{dataSalesShow.campaign.campaign_bonus}}%</strong></div>
                        <div><span>{{ getMyLanguage("modal", "modal.sale-description.fee") }}:</span> <strong>{{dataSalesShow.sale.sale_fee}}%</strong></div>
                        <div class="finalPrice"><span>{{ getMyLanguage("modal", "modal.sale-description.final-price") }}:</span> <strong>{{dataSalesShow.sale.sale_final_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span class="fiat"></span></strong></div>
                        <div class="finalPrice"><span>{{ getMyLanguage("modal", "modal.sale-description.commission") }}:</span> <strong>{{dataSalesShow.commission.commission_amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span class="fiat"></span></strong></div>
                        <!-- <div><span>{{ getMyLanguage("modal", "modal.sale-description.payDay") }}:</span> <strong v-html="dateTimeFull(dataSalesShow.commission.commission_pay_day)"></strong></div> -->
                    </div>

                    <!-- <div class="btn-primary" @click="newSale(dataSalesShow.id)">{{ getMyLanguage("modal", "modal.sale-description.new-sale") }}</div> -->
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="this.modalShow === true"></div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';

    import moment from 'moment';
    
    import apiSales from '@/config/apiSales.js'

    export default {
        components: {
            Header
        },

        data () {
            return {
                lengthSales: 'load',
                dataSales: [],
                dataSalesShow: [],
                flagSelected: {},
                filter: false,
                filtersParam: {},
                filtersCheck: {},
                modalShow: false,
                nameAgentFilter: false,
                showBack: false,

                errorsGet: {
                    dataSales: false
                }
            }
        },
        
        async mounted () {
            const urlParams = new URLSearchParams(window.location.search);
            const accountIdParams = urlParams.get('account_id');
            const stageParams = urlParams.get('stage');
            const nameLead = urlParams.get('name')

            if ( nameLead == null ){
                this.nameAgentFilter = localStorage.getItem("agentName")
            } else {
                this.nameAgentFilter = nameLead
                this.showBack = true
            }

            if ( accountIdParams != null){
                apiSales.get('/api/v1/sales/commissions?account_id=' + accountIdParams)
                .then(response => {
                    this.dataSales = response.data

                    if ( this.dataSales.length > 0 ){
                        this.lengthSales = true
                    } else{
                        this.lengthSales = false
                    }

                })
                .catch(error => {
                    this.errorsGet.dataSales = error.response.status

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }
                })

            } else if ( stageParams != null ){    
                apiSales.get('/api/v1/sales/commissions?stage=' + stageParams)
                .then(response => {
                    this.dataSales = response.data

                    if ( this.dataSales.length > 0 ){
                        this.lengthSales = true
                    } else{
                        this.lengthSales = false
                    }

                })
                .catch(error => {
                    this.errorsGet.dataSales = error.response.status

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }
                })
            } else{
                apiSales.get('/api/v1/sales/commissions')
                .then(response => {
                    this.dataSales = response.data

                    if ( this.dataSales.length > 0 ){
                        this.lengthSales = true
                    } else{
                        this.lengthSales = false
                    }
                })
                .catch(error => {
                    this.errorsGet.dataSales = error.response.status

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }
                })
            }   
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            viewSale(value){
                this.dataSalesShow = this.dataSales[value]
                this.modalShow = true
            },

            async openToastError() {
                this.$toast.error(this.getMyLanguage("box-error","msg-error"));
                setTimeout(this.$toast.clear, 6000)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-sales.scss" lang="scss" scoped />