<template>
    <div class="profile">
        <Header />

        <div class="content">
            <h3 class="title-secondary">{{ getMyLanguage("profile", "profile.title") }}</h3>

            <div class="box-error" v-if="errorsGet.dataProfile != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataProfile}}
                </div>
            </div>

            <div class="boxes-seller" v-if="!errorsGet.dataProfile">
                <div class="box box-seller">
                    <h3>{{ getMyLanguage("profile","profile.subtitle.my-profile") }}</h3>
                    <label>
                        {{ getMyLanguage("profile", "profile.label.agentType") }}
                        <div class="form form-disabled"><span>{{ getMyLanguage("status", dataProfile.agentType) }}</span></div>
                    </label>
                    
                    <label>
                        {{ getMyLanguage("profile", "profile.label.name") }}
                        <div class="form form-disabled"><span>{{dataProfile.name}}</span></div>
                    </label>
                    
                    <label>
                        {{ getMyLanguage("profile", "profile.label.email") }}
                        <div class="form form-disabled"><span>{{dataProfile.email}}</span></div>
                    </label>

                    <label>
                        {{ getMyLanguage("profile", "profile.label.phone") }}
                        <div class="form form-disabled"><span>{{dataProfile.phoneNumber}}</span></div>
                    </label>
                </div>
                
                <div class="box box-parent-seller">
                    <h3>{{ getMyLanguage("profile","profile.subtitle.agent-profile") }}</h3>
                    <label>
                        {{ getMyLanguage("profile", "profile.label.parentAgentType") }}
                        <div class="form form-disabled"><span>{{ getMyLanguage("status", dataProfile.parentAgentType) }}</span></div>
                    </label>
                    
                    <label>
                        {{ getMyLanguage("profile", "profile.label.name") }}
                        <div class="form form-disabled"><span>{{dataProfile.parentName}}</span></div>
                    </label>

                    <label>
                        {{ getMyLanguage("profile", "profile.label.email") }}
                        <div class="form form-disabled"><span>{{dataProfile.parentEmail}}</span></div>
                    </label>

                    <label>
                        {{ getMyLanguage("profile", "profile.label.phone") }}
                        <div class="form form-disabled"><span>{{dataProfile.parentPhoneNumber}}</span></div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import apiSales from '@/config/apiSales.js';

    import Header from '@/components/Header.vue';

    export default {
        components: {
            Header
        },

        data () {
            return {
                flagSelected: {},
                dataProfile: [],

                errorsGet: {
                    dataProfile: false
                }
            }
        },
        
        async mounted () {
            apiSales.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataProfile = response.data
            })
            .catch(error => {
                this.errorsGet.dataProfile = error.response.status

                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }
            })
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-profile.scss" lang="scss" scoped />