<template>
    <div class="403">
        <Header />

        <div class="content">
            <h3 class="title-secondary">{{getMyLanguage("errors", "error-403.title")}}</h3>
            
            <div class="erro403">
                <img src="~@/assets/images/icons/403.svg" alt="Error 403">
                <div class="box msg-error">
                    <p>{{getMyLanguage("errors","error-403.msg")}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    import Header from '@/components/Header.vue';

    export default {
        components: {
            Header
        },

        data () {
            return {
                flagSelected: {},
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style scoped>
    .erro403{display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; margin: 0 auto; max-width: 400px;}
    .erro403 img{margin-bottom: 50px;}
    .erro403 .msg-error{width: 100%; text-align: center;}
    .erro403 .msg-error p{margin: 0;}
</style>