<template>
    <div class="agents">
        <Header />
                
        <div class="content">
            <h3 class="title-secondary" v-if="!filter">
                {{ getMyLanguage("agents", "agents.title") }}
            </h3>

            <div class="box-error" v-if="errorsGet.dataAgents != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataAgents}}
                </div>
            </div>

            <div v-if="!errorsGet.dataAgents">
            
                <div class="load load-center" v-if="this.lengthAgent === 'load'"></div>
                
                <div class="search-bar" v-if="dataAgents.length > 0 && !filter">
                    <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("agents","agents.search")'>
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                <div class="filter box" v-if="filter">
                    <div class="close-filter" @click="filter = false"></div>
                </div>


                <div class="box" v-if="lengthAgent === true">
                    <div class="scroll-horizontal">
                        <table class="list-result">
                            <thead class="header-list">
                                <tr>
                                    <th class="date">{{ getMyLanguage("list-result","since") }}</th>
                                    <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>
                                    <th class="agent">{{ getMyLanguage("list-result","agent") }}</th>
                                    <th class="region">{{ getMyLanguage("list-result","region") }}</th>
                                    <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody id="drop-list">
                                <tr v-for="(agentItem,index) in dataAgents" :key="index" class="list">
                                    <td class="date" @click="viewSale(agentItem.agentId)"><span v-html="dateTime(agentItem.createdAt)"></span></td>
                                    <td class="fullName" @click="viewSale(agentItem.agentId)"><span>{{agentItem.name}}</span></td>
                                    <td class="agent" @click="viewSale(agentItem.agentId)"><span>{{ getMyLanguage("status", agentItem.agentType) }}</span></td>
                                    <td class="region" @click="viewSale(agentItem.agentId)"><span>{{ getMyLanguage("status", agentItem.regionName) }}</span></td>
                                    <td class="status" :class="agentItem.status" @click="viewSale(agentItem.agentId)"><span>{{ getMyLanguage("status", agentItem.status) }}</span></td>
                                    <td class="sales">
                                        <span @click="listSales(agentItem.accountId, agentItem.name)" v-if='agentItem.status === "Activated"'>{{ getMyLanguage("leads","list.sales") }}</span>
                                        <small v-if='agentItem.status != "Activated"'>- -</small>
                                    </td>
                                    <td class="leads">
                                        <span @click="listCustomers(agentItem.accountId, agentItem.name)" v-if='agentItem.status === "Activated"'>{{ getMyLanguage("menu","customers") }}</span>
                                        <small v-if='agentItem.status != "Activated"'>- -</small>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="no-results" v-if="!lengthAgent">
                    <p>{{ getMyLanguage("agents", "no-agents") }}</p>
                </div>
            </div>
        </div>
            
            
        <!-- MODAL -->
        <div class="modal" v-if="modalShow">
            <div @click="modalShow = false" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll-vertical">
                    <h3>{{dataAgentsShow.name}}</h3>

                    <div class="sale-description" :class="dataAgentsShow.status">
                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.agentSince") }}:</span> <strong>{{ dateTimeFull(dataAgentsShow.createdAt) }}</strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.agentType") }}:</span> <strong>{{dataAgentsShow.agentType}}</strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.regionName") }}:</span> <strong>{{dataAgentsShow.regionName}}</strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.status") }}:</span> <strong>{{dataAgentsShow.status}}</strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.phone") }}:</span> <strong>{{dataAgentsShow.phoneNumber}}</strong></div>
                        <div class="fullField divider"><span>{{ getMyLanguage("profile", "profile.label.email") }}:</span> <strong>{{dataAgentsShow.email}}</strong></div>

                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.parentAgentType") }}:</span> <strong>{{dataAgentsShow.parentName}}</strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.regionName") }}:</span> <strong>{{dataAgentsShow.parentRegionName}}</strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.phone") }}:</span> <strong>{{dataAgentsShow.parentPhoneNumber}}</strong></div>
                        <div class="fullField"><span>{{ getMyLanguage("profile", "profile.label.email") }}:</span> <strong>{{dataAgentsShow.parentEmail}}</strong></div>

                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="this.modalShow === true"></div>

    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';

    import moment from 'moment';
    import apiSales from '@/config/apiSales.js'

    export default {
        components: {
            Header
        },

        data () {
            return {
                lengthAgent: 'load',
                dataAgents: [],
                dataAgentsShow: [],
                flagSelected: {},
                filter: false,
                modalShow: false,

                dataInviteAgent: [],
                dataRecruit: {
                    regionId: "72ded956-b703-477c-bf4b-1d8566618d42",
                    agentType: "ce930f11-b951-4382-a784-fd495b2ad487"
                },

                modalInviteAgent: false,
                urlInvite: {},

                errorsGet: {
                    dataAgents: false
                }
            }
        },
        
        async mounted () {
            // const resumeClients = await apiSales.get('/api/v1/agent/agents')
            // this.dataAgents = resumeClients.data

            apiSales.get('/api/v1/agent/agents')
            .then(response => {
                this.dataAgents = response.data

                if ( this.dataAgents.length > 0 ){
                    this.lengthAgent = true
                } else{
                    this.lengthAgent = false
                }
            })
            .catch(error => {
                this.errorsGet.dataAgents = error.response.status
            })
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            viewSale(id){
                this.dataAgentsShow = this.dataAgents.filter(item => item.agentId === id)[0]
                this.modalShow = true
            },

            listCustomers(value, name){
                window.location.href = '/' + this.flagSelected + '/agents/customers?account_id=' + value + "&name=" + name
            },
            
            listSales(value, name){
                window.location.href = '/' + this.flagSelected + '/sales?account_id=' + value + "&name=" + name
            },

            newSale(id){
                const idLead = id
                const languageRedirect = String(window.location.pathname).split("/")[1];
                window.location.href = '/' + languageRedirect + '/leads/sale/' + idLead
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-agents.scss" lang="scss" scoped />