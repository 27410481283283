<template>
    <div class="404">
        <Header />

        <div class="content">
            <h3 class="title-secondary">{{getMyLanguage("errors", "error.404")}}</h3>
            
            <div class="erro404">
                <img src="~@/assets/images/icons/404.svg" alt="Page not found">
            </div>                    
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    import Header from '@/components/Header.vue';

    export default {
        components: {
            Header
        },

        data () {
            return {
                flagSelected: {},
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style scoped>
    .erro404{display: flex; justify-content: center; width: 100%;}
    .erro404 img{width: 80%; max-width: 400px;}
</style>