<template>
    <div class="dash">
        <Header />

        <div class="content">
            <div class="load load-center" v-if="!showContent"></div>
            
            <div class="box-error" v-if="errorsGet.dataDash != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataDash}}
                </div>
            </div>

            
            <!-- CARDS -->
            <h3 class="title-secondary" v-if="showContent">
                {{getMyLanguage("dashboard","dashboard.title")}}
                <small>{{dataDash.name}}</small>    
            </h3>
            
            <div class="cardsDash" v-if="showContent">

                <!-- SALES WINS -->
                <div class="group-cards group-wins">
                    <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-wins")}}</div>

                    <div class="cards">
                        <div class="box card card-total" @click='lnkSales("?stage=wins")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                    <h4>{{dataDash.wins.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card card-total">
                            <div class="card-content" @click='lnkSales("?stage=wins")'>
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                    <h4>{{dataDash.wins.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content" @click='lnkSales("?stage=wins")'>
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                    <h4>{{dataDash.wins.packages}} / {{dataDash.totalPackages}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card" @click='lnkLeads("?stage=wins")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.conversions-leads")}}</p>
                                    <h4>{{dataDash.wins.leads}} / {{dataDash.totalLeads}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- SALES PROGRESS -->
                <div class="group-cards group-progress">
                    <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-in-progress")}}</div>

                    <div class="cards">
                        <div class="box card card-total" @click='lnkSales("?stage=draw")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                    <h4>{{dataDash.draw.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card card-total" @click='lnkSales("?stage=draw")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                    <h4>{{dataDash.draw.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card" @click='lnkSales("?stage=draw")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                    <h4>{{dataDash.draw.packages}} / {{dataDash.totalPackages}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card" @click='lnkLeads("?stage=draw")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.conversions-leads")}}</p>
                                    <h4>{{dataDash.draw.leads}} / {{dataDash.totalLeads}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- COMMISSIONS -->
                <div class="group-cards group-commissions">
                    <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.commissions")}}</div>

                    <div class="cards">
                        <!-- <div class="box card card-total" @click="modalWithdraw.modal = true"> -->
                        <div class="box card card-total">
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.available")}}</p>
                                    <h4>{{dataDash.commissions.available.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-withdraw.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card card-total" @click='lnkSales("?status=waitingConfirmation")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.waiting-confirmation")}}</p>
                                    <h4>{{dataDash.commissions.waitingConfirmation.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card" @click='lnkSales("?status=pendingApproval")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.pending-approval")}}</p>
                                    <h4>{{dataDash.commissions.pendingApproval.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-pending-approval.svg" class="icon">
                            </div>
                        </div>

                        <div class="box card" @click='lnkSales("?status=received")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.received")}}</p>
                                    <h4>{{dataDash.commissions.received.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-received.svg" class="icon">
                            </div>
                        </div>

                        <!-- <div class="box card card-total" @click='lnkSales("?status=waitingPayment")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.waiting-payment")}}</p>
                                    <h4>{{dataDash.commissions.waitingPayment.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-waiting.svg" class="icon">
                            </div>
                        </div> -->

                        <!-- <div class="box card" @click='lnkSales("?stage=loss")'>
                            <div class="card-content">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.cancelled")}}</p>
                                    <h4>{{dataDash.commissions.cancelled.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                                <img src="~@/assets/images/icons/dash-card-cancelled.svg" class="icon">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        
        
        <!-- REFERRAL PLAN -->
        <ReferralPlan />
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';
    import ReferralPlan from '@/components/ReferralPlan.vue';

    import apiSales from '@/config/apiSales.js'
    import moment from 'moment';

    export default {
        components: {
            Header,
            ReferralPlan
        },

        data () {
            return {
                showCart: false,
                
                flagSelected: {},
                showContent: false,
                dataDash: {},

                accountID: '',

                errorsGet: {
                    dataDash: false
                }
            }
        },
        
        async mounted () {

            if ( this.$route.params.registerId === undefined ){
                await apiSales.get('/api/v1/reports/commissions/agent/dashboard')
                .then(response => {
                    this.dataDash = response.data
                    this.dataDash.name = localStorage.getItem("agentName")

                    setTimeout(() => {
                        this.showContent = true
                    }, 100);
                })
                .catch(error => {
                    this.errorsGet.dataDash = error

                    setTimeout(() => {
                        this.showContent = true
                    }, 100);

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }
                })
            
            } else{
                await apiSales.get('/api/v1/reports/commissions/agent/dashboard/' + this.$route.params.registerId)
                .then(response => {
                    this.dataDash = response.data

                    setTimeout(() => {
                        this.showContent = true
                    }, 100);
                })
                .catch(error => {
                    this.errorsGet.dataDash = error

                    setTimeout(() => {
                        this.showContent = true
                    }, 100);

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }
                })
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'MM/DD' : 'DD/MM';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            validateWithdraw(){
                this.modalWithdraw.finish = false
                this.modalWithdraw.success = true

                setTimeout(() => {
                    this.successWithdraw = true
                }, 2000);
            },

            lnkSales(value){
                this.accountID = String(window.location.pathname).split("dashboard/")[1];
                
                if ( this.accountID == null ){
                    window.location.href = this.flagSelected + '/sales' + value
                } else {
                    window.location.href = this.flagSelected + '/sales' + value + "&account_id=" + this.accountID
                }

            },

            lnkLeads(value){
                window.location.href = this.flagSelected + '/leads' + value
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-dash.scss" lang="scss" scoped />