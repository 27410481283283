<template>
    <div class="invites">
        <Header />

        <div class="content">
            <h3 class="title-secondary" v-if="!filter">
                {{ getMyLanguage("invites", "invites.title") }}
                <small v-if="nameAgentFilter">{{nameAgentFilter}}</small>
            </h3>

            <div class="box-error" v-if="errorsGet.dataInvites != false">
                <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataInvites) }}</p> -->
                <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataInvites}}
                </div>
            </div>

            <div v-if="!errorsGet.dataInvites">
                
                <div class="load load-center" v-if="this.lengthInvites === 'load'"></div>

                <div class="search-bar" v-if="lengthInvites === true && !filter">
                    <input type="text" class="form form-search" id="search" v-on:keyup="filterList" :placeholder='getMyLanguage("agents","agents.search")'>
                    <div class="btn-filter" @click="filter = true"></div>
                    <div class="btn-back" v-if="showBack" onclick="history.back()"></div>
                </div>

                <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                <div class="filter box" v-if="filter">
                    <div class="close-filter" @click="filter = false"></div>
                </div>

                <div class="box" v-if="lengthInvites === true">
                    <div class="scroll-horizontal">
                        <table class="list-result">
                            <thead class="header-list">
                                <tr>
                                    <!-- <th class="fullName text-left">{{ getMyLanguage("list-result","invited-by") }}</th> -->
                                    <th class="fullName text-left">{{ getMyLanguage("list-result","invitat to") }}</th>
                                    <th class="date">{{ getMyLanguage("list-result","createdAt") }}</th>
                                    <th class="date">{{ getMyLanguage("list-result","expiresAt") }}</th>
                                    <th class="type">{{ getMyLanguage("list-result","type") }}</th>
                                    <!-- <th class="delivery">{{ getMyLanguage("list-result","delivery") }}</th> -->
                                    <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                    <th class="invites" v-if='$store.state.agent.agentTypeCode === "regional_director" || $store.state.agent.agentTypeCode === "master_franchising"'>{{ getMyLanguage("list-result","invite") }}</th>
                                </tr>
                            </thead>

                            <tbody id="drop-list">
                                <tr class="list" v-for="(inviteItem,index) in dataInvites" :key="index">
                                    <!-- <td class="fullName text-left"><span>{{inviteItem.agent_name}}</span></td> -->
                                    <td class="fullName text-left">
                                        <span v-if='inviteItem.invite_status != "Generated"'>{{inviteItem.account_full_name}}</span>
                                        <span v-if='inviteItem.invite_status === "Generated"' class="pending">{{inviteItem.invite_description}}</span>
                                        <!-- <span v-if='inviteItem.invite_type_code === "agent_invite" && inviteItem.invite_status === "Generated"' class="pending">{{inviteItem.account_full_name}}</span> -->
                                    </td>
                                    <td class="date"><span>{{dateTime(inviteItem.invite_created_at)}}</span></td>
                                    <td class="date"><span>{{dateTime(inviteItem.invite_expires_at)}}</span></td>
                                    <td class="type"><span>{{ getMyLanguage("status", inviteItem.invite_type_code) }}</span></td>
                                    <!-- <td class="delivery" :class="inviteItem.delivery_status">
                                        <div class="status-delivery"></div>
                                        <div class="tooltip-delivery">{{inviteItem.delivery_status_reason}}</div>
                                    </td> -->
                                    <td class="status tooltip" :class="inviteItem.invite_status">
                                        <span>{{ getMyLanguage("status", inviteItem.invite_status) }}</span>
                                    
                                        <div class="tooltip">{{ getMyLanguage("invites","tooltip." + inviteItem.invite_status) }}</div>
                                    </td>
                                    <td class="invites" v-if='$store.state.agent.agentTypeCode === "regional_director" || $store.state.agent.agentTypeCode === "master_franchising"'>
                                        <span @click="inviteItems(inviteItem.account_id, inviteItem.account_full_name)" v-if="inviteItem.is_agent">{{ getMyLanguage("menu", "invites") }}</span>
                                        <small v-if="!inviteItem.is_agent">- -</small>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="no-results" v-if="!lengthInvites">
                    <p>{{ getMyLanguage("invites", "no-invite") }}</p>
                    <div class="btn-back" onclick="history.back()"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';

    import apiSales from '@/config/apiSales.js'
    import moment from 'moment';

    export default {
        components: {
            Header
        },

        data () {
            return {
                flagSelected: {},
                dataInvites: [],

                errorsGet: {
                    dataInvites: false
                },

                lengthInvites: "load",
                nameAgentFilter: false,
                showBack: false,
                
                filter: false,
                filtersParam: {},
            }
        },
        
        async mounted () {
            const urlParams = new URLSearchParams(window.location.search);
            const accountIdParams = urlParams.get('account_id');
            const nameLead = urlParams.get('name')

            if ( nameLead == null ){
                this.nameAgentFilter = localStorage.getItem("agentName")
            } else {
                this.nameAgentFilter = nameLead
                this.showBack = true
            }

            if ( accountIdParams == null || accountIdParams == '' ){
                apiSales.get('/api/v1/agent/invites')
                .then(response => {
                    this.dataInvites = response.data

                    if ( this.dataInvites.length > 0 ){
                        this.lengthInvites = true
                    } else{
                        this.lengthInvites = false
                    }
                })
                .catch(error => {
                    this.errorsGet.dataInvites = error.response.status

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }
                })
            } else{
                apiSales.get('/api/v1/agent/invites?account_id=' + accountIdParams)
                .then(response => {
                    this.dataInvites = response.data

                    if ( this.dataInvites.length > 0 ){
                        this.lengthInvites = true
                    } else{
                        this.lengthInvites = false
                    }
                })
                .catch(error => {
                    this.errorsGet.dataInvites = error.response.status

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }
                })
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'MM/DD' : 'DD/MM';
                return moment(value).format(format);
            },

            dateTimeYear(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("search");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },
            
            inviteItems(value, name){
                window.location.href = '/' + this.flagSelected + '/invites?account_id=' + value + "&name=" + name
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-invites.scss" lang="scss" scoped />