<template>
    <div class="activate-success">
        <div class="content">
            <div class="load" v-if="load"><span></span></div>
            
            <div class="content-success" v-if="!load">
                <div class="logo-dne-activate">
                    <img src="@/assets/images/logo-dne.svg">
                </div>

                <h3 class="title-secondary">{{ getMyLanguage("activate","title.success") }}</h3>

                <div class="box">
                    <h4 class="msg-success">{{ getMyLanguage("activate","msg-activation") }}</h4>

                    <p class="msg-login">{{ getMyLanguage("activate","choose-option") }}</p>

                    <div class="login-by">
                        <div class="btn-login web" @click="lnkBrowser()">{{ getMyLanguage("activate","browser") }}</div>
                        <div class="btn-login download-app"><img src="~@/assets/images/app-dne-download-android.svg" alt="Download app for Android"></div>
                        <div class="btn-login download-app"><img src="~@/assets/images/app-dne-download-ios.svg" alt="Download app for Android"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        data () {
            return {
                flagSelected: {},
                dataActivate: [],
                load: true,
                accept: false,
                validating: false
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            lnkBrowser(){
                window.location.href = this.flagSelected + '/login'
            }
        },
        
        async mounted () {
            // const resumeDataActivate = await apiSales.get('api/v1/platform/account/agent/activate/3fa85f64-5717-4562-b3fc-2c963f66afa6')
            // this.dataActivate = resumeDataActivate.data

            setTimeout(() => {
                this.load = false
            }, 200);
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-activate-success.scss" lang="scss" scoped />