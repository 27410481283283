<template>
    <div class="sales-force">
        <Header />

        <div class="content ">
            
            <!-- CARDS -->
            <h3 class="title-secondary">{{getMyLanguage("sales","sales-force.title")}}</h3>
            
            <div class="box-error" v-if="errorsGet.dataDash != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataDash}}
                </div>
            </div>
            
            <div class="load load-center" v-if="!showContent"><span></span></div>

            <div class="cardsDash" v-if="showContent && !errorsGet.dataDash">

                <!-- SALES WINS -->
                <div class="group-cards group-wins">
                    <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-wins")}}</div>

                    <div class="cards">
                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                    <h4>{{dataDash.wins.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                    <h4>{{dataDash.wins.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                    <h4>{{dataDash.wins.packages}} / {{dataDash.totalPackages}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.leads")}}</p>
                                    <h4>{{dataDash.wins.leads}} / {{dataDash.totalLeads}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-average-ticket.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.conversions")}}</p>
                                    <h4>{{dataDash.wins.conversions}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-amount-sales.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.average-sale")}}</p>
                                    <h4>{{dataDash.wins.averageSale.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.conversions-ratio")}}</p>
                                    <h4>{{dataDash.wins.conversionsRatio.toLocaleString("pt-PT", {style:"percent", maximumFractionDigits: 2})}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- SALES PROGRESS -->
                <div class="group-cards group-progress">
                    <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-in-progress")}}</div>

                    <div class="cards">
                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                    <h4>{{dataDash.draw.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                    <h4>{{dataDash.draw.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                    <h4>{{dataDash.draw.packages}} / {{dataDash.totalPackages}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.leads")}}</p>
                                    <h4>{{dataDash.draw.leads}} / {{dataDash.totalLeads}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-average-ticket.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.conversions")}}</p>
                                    <h4>{{dataDash.draw.conversions}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-amount-sales.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.average-sale")}}</p>
                                    <h4>{{dataDash.draw.averageSale.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.conversions-ratio")}}</p>
                                    <h4>{{dataDash.draw.conversionsRatio.toLocaleString("pt-PT", {style:"percent", maximumFractionDigits: 2})}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- SALES LOSS -->
                <div class="group-cards group-loss">
                    <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.sales-loss")}}</div>

                    <div class="cards">
                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-sales.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-sold")}}</p>
                                    <h4>{{dataDash.loss.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-commission.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-commission")}}</p>
                                    <h4>{{dataDash.loss.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-packages.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.packages")}}</p>
                                    <h4>{{dataDash.loss.packages}} / {{dataDash.totalPackages}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.leads")}}</p>
                                    <h4>{{dataDash.loss.leads}} / {{dataDash.totalLeads}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-average-ticket.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.conversions")}}</p>
                                    <h4>{{dataDash.loss.conversions}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-amount-sales.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.average-sale")}}</p>
                                    <h4>{{dataDash.loss.averageSale.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.conversions-ratio")}}</p>
                                    <h4>{{dataDash.loss.conversionsRatio.toLocaleString("pt-PT", {style:"percent", maximumFractionDigits: 2})}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- COMMISSIONS -->
                <div class="group-cards group-commissions">
                    <div class="header-group">{{getMyLanguage("dashboard","card-dash.title.commissions")}}</div>

                    <div class="cards">
                        <div class="box card">
                            <div class="card-content total-withdraw">
                                <img src="~@/assets/images/icons/dash-card-leads.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.total-leads")}}</p>
                                    <h4>{{dataDash.totalLeads}}</h4>
                                </div>
                            </div>
                        </div>
                        
                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-withdraw.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.available")}}</p>
                                    <h4>{{dataDash.commissions.available.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-waiting.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.waiting-payment")}}</p>
                                    <h4>{{dataDash.commissions.waitingPayment.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-test.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.waiting-confirmation")}}</p>
                                    <h4>{{dataDash.commissions.waitingConfirmation.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-received.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.received")}}</p>
                                    <h4>{{dataDash.commissions.received.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-pending-approval.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.pending-approval")}}</p>
                                    <h4>{{dataDash.commissions.pendingApproval.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>

                        <div class="box card">
                            <div class="card-content">
                                <img src="~@/assets/images/icons/dash-card-cancelled.svg" class="icon">
                                <div>
                                    <p>{{getMyLanguage("dashboard","card-dash.cancelled")}}</p>
                                    <h4>{{dataDash.commissions.cancelled.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- LIST AGENTS -->
            <div class="list-agents" v-if="showContent && !errorsGet.dataDash">
                <h3 class="title-secondary" v-if="this.lengthAgent != 'load' && filter">{{getMyLanguage("sales","sales-force.title.by-agents")}}</h3>
                
                <div class="load load-center" v-if="this.lengthAgent === 'load'"></div>
                
                <div class="search-bar" v-if="dataAgents.length > 0 &&  filter">
                    <input type="text" class="form form-search" id="searchAgent" v-on:keyup="filterList" :placeholder='getMyLanguage("agents","agents.search")'>
                    <div class="btn-filter" @click="filter = true"></div>
                    <!-- <div class="btn-add" @click="newInviteAgent()">{{ getMyLanguage("agents", "agents.add") }}</div> -->
                </div>

                <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                <div class="filter box" v-if="filter">
                    <div class="close-filter" @click="filter = false"></div>
                </div>

                <div class="box" v-if="lengthAgent">
                    <div class="scroll-horizontal">
                        <table class="list-result">
                            <thead class="header-list">
                                <tr>
                                    <th class="fullName">{{ getMyLanguage("list-result","name") }}</th>
                                    <th class="">{{ getMyLanguage("list-result","stage") }}</th>
                                    <th class="">{{ getMyLanguage("list-result","total-sold") }}</th>
                                    <th class="">{{ getMyLanguage("list-result","commission") }}</th>
                                    <th class="">{{ getMyLanguage("list-result","packages") }}</th>
                                    <th class="">{{ getMyLanguage("list-result","leads") }}</th>
                                    <th class="last-tablet">{{ getMyLanguage("list-result","conversions") }}</th>
                                    <th class="hide-tablet">{{ getMyLanguage("list-result","average-sale") }}</th>
                                    <th class="hide-tablet last">{{ getMyLanguage("list-result","ratio") }}</th>
                                </tr>
                            </thead>

                            <tbody id="drop-list">
                                <tr v-for="(agentItem,index) in dataAgents" :key="index" @click="lnkDash(agentItem.agentId)" class="list" :class="agentItem.stage">
                                    <td class="fullName"><span>{{agentItem.agentName}}</span></td>
                                    <td class="text-right stage"><span>{{agentItem.stage}}</span></td>
                                    <td class="text-right"><span>{{agentItem.totalSold.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                    <td class="text-right"><span>{{agentItem.totalCommission.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                    <td class="text-right"><span>{{agentItem.packages}}</span></td>
                                    <td class="text-right"><span>{{agentItem.leads}}</span></td>
                                    <td class="text-right last-tablet"><span>{{agentItem.conversions}}</span></td>
                                    <td class="text-right hide-tablet"><span>{{agentItem.averageSale.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                    <td class="text-right hide-tablet last"><span>{{agentItem.conversionsRatio.toLocaleString("pt-PT", {style: "percent"})}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="no-results" v-if="!lengthAgent">
                    <p>{{ getMyLanguage("agents", "no-agents") }}</p>
                </div>
            </div>


            <!-- Chart -->
            <!-- <div class="box Chart" v-if="showContent">
                <Chart :options="dataChart" />
            </div> -->
            
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';

    import apiSales from '@/config/apiSales.js'
    import moment from 'moment';

    // import { Chart } from 'highcharts-vue'

    export default {
        components: {
            Header,
            // Chart
        },

        data () {
            return {
                flagSelected: {},
                lengthAgent: 'load',
                filter: false,
                dataAgents: [],
                showContent: false,
                dataDash: {},

                errorsGet: {
                    dataDash: false
                },

                // dataChart: {}
            }
        },
        
        async mounted () {
            apiSales.get('/api/v1/reports/commissions/agents/director')
            .then(response => {
                this.dataDash = response.data
                this.dataAgents = this.dataDash.agents
                
                 if ( this.dataAgents.length > 0 ){
                    this.lengthAgent = true
                } else{
                    this.lengthAgent = false
                }
                
                setTimeout(() => {
                    this.showContent = true
                }, 500);
            })
            .catch(error => {
                this.errorsGet.dataDash = error.response.status

                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }

                setTimeout(() => {
                    this.showContent = true
                }, 500);
            })

            

            // const resumeClients = await apiSales.get('/api/v1/agent/agents')
            // this.dataAgents = resumeClients.data
            
            // const resumeChart = await apiSales.get('/api/v1/reports/commissions/agent/weekly')
            // this.dataChart = resumeChart.data
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'MM/DD' : 'DD/MM';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchAgent");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            lnkDash(id){
                window.location.href = this.flagSelected + '/dashboard/' + id
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-salesforce.scss" lang="scss" scoped />