<template>
    <div class="agent-sign">
        <div class="content">
            
            <div class="load" v-if="!showContent && !errorsGet.agentSign"><span></span></div>

            <div class="box-error" v-if="errorsGet.agentSign != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataAgentSign}}
                </div>
            </div>
            
            <div class="box-error" v-if="errorsPost.agentSign != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsPost.dataAgentSign}}
                </div>
            </div>
            
            <!-- SIGNATURE -->
            <div class="signature box" v-if="showContent && !errorsGet.agentSign">
                <iframe :src="urlContract" frameborder="0"></iframe>
            </div>

            <!-- ACCEPT -->
            <div class="accepts" v-if="showContent && !errorsGet.agentSign">
                <div class="form-group-check">
                    <input type="checkbox" id="acceptTerms" class="form-check" v-model="signed">
                    <label for="acceptTerms" class="form-check-label"><small>{{ getMyLanguage("activate","activate.signet-contract") }}</small></label>
                </div>
            
                <div class="btn-primary btn-disable" id="btnRegister" v-if="!signed">{{ getMyLanguage("activate","activate.btn") }}</div>
                <div class="btn-primary" id="btnRegister" v-if="signed" @click="confirmSign()">{{ getMyLanguage("activate","activate.btn") }}</div>
            </div>

        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import apiSales from '@/config/apiSales.js'
    
    export default {
        data () {
            return {
                flagSelected: {},
                showContent: false,
                signed: false,

                urlContract: "",
                dataAgentSign: {},

                errorsGet: {
                    agentSign: false
                },

                errorsPost: {
                    agentSign: false
                }
            }
        },
        
        async mounted () {
            await apiSales.get('/api/v1/agent/contract/sign')
            .then(response => {
                this.dataAgentSign = response.data
                this.showContent = true
                
                this.urlContract = this.dataAgentSign.urlToSign
            })
            .catch(error => {
                this.errorsGet.dataAgentSign = error.response.data
                this.errorsGet.agentSign = true
            })
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            confirmSign(){
                apiSales.post('/api/v1/agent/contract/sign')
                .then(response => {
                    window.location.href =  this.flagSelected + '/dashboard/'
                    console.log(response)
                })
                .catch(error => {
                    this.errorsPost.dataAgentSign = error.response.data
                    this.errorsPost.agentSign = true
                })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-agent-sign.scss" lang="scss" scoped />