<template>
    <div class="invoice">
        <div class="load" v-if="!showContent">
            <small>{{ getMyLanguage("activate","activate.load") }}</small>
        </div>

        <vue3-simple-html2pdf
            ref="vue3SimpleHtml2pdf"
            :options="pdfOptions"
            :filename="exportFilename"
            >

            <div v-if="showContent && !errorsGet">
                <h2 style="text-align: center; color: #1f3561; margin-top: 0 !important; margin-bottom: 35px !important">Services Agreement<br>Commercial Seller</h2>
                <p style="font-size: 13px !important; line-height: 18px !important; margin-bottom: 0px">Between</p>
                <p style="font-size: 13px !important; line-height: 18px !important;"><strong>DNE INTERNATIONAL SAGL</strong>, headquartered at Via Cantonale 11, 6900 Lugano, Switzerland, company number CHE-409.547.100, represented herein by its Directors empowered to act, JORGE VIEIRA CABRAL, NIF 189408162, CC 06535738; and LUÍS CARLOS GONÇALVES SANTOS NIF 214974065, CC 11196024 (hereinafter 1st Party).</p>
                <p style="font-size: 13px !important; line-height: 18px !important; margin-bottom: 0px">And</p>
                <p style="font-size: 13px !important; line-height: 18px !important;"><strong>{{dataContract.fullName}}</strong> natural person with Tax Number <strong>{{dataContract.taxNumber}}</strong>, domiciled at <strong>{{dataContract.address.address1}}</strong> nº <strong>{{dataContract.address.number}}</strong> <strong>{{dataContract.address.address2}}</strong>, <strong>{{dataContract.address.postalCode}}</strong> <strong>{{dataContract.address.district}}/{{dataContract.address.country}}</strong>, holder of <strong>{{dataContract.documentType}}</strong>  nº <strong>{{dataContract.documentNumber}}</strong> and valid until <strong>{{ dateTime(dataContract.documentValidThru)}}</strong> (hereinafter 2nd Party).</p>
                
                <h6 style="color: #1f3561; margin-top: 30px !important;">Considerations:</h6>
                <p style="font-size: 13px !important; line-height: 16px !important;">I.- The 1st Party expresses an interest in having an employee who performs commercial functions, who promotes the contracting of services and / or products from said entity, as well as from its subsidiaries, so it has negotiated contractual conditions with the "Commercial Seller - 2nd Party ” for the formalization of an agreement whose object is this document.</p>
                <!-- <p style="font-size: 13px !important; line-height: 16px !important;">II.- That both parties acknowledge and accept that the “Commercial Seller – 2nd Party” was referenced and presented in commercial terms by the “Commercial Director” of the 1st Party. The Commercial Seller only acquires this quality because he is invited by the Commercial Director and, before all parties, accepts and gives his consent to be bound by this contract.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">III.- That, due to the aforementioned negotiations and introductions, both parties have collaborated assiduously and maintain a better knowledge of the scope of the contractual object that is intended and, therefore, freely and mutually agree to sign this service agreement.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">IV.- That the contract for the provision of services will consist of this document and the annexes, additions and/or additional incentives that the parties decide to agree upon by mutual agreement and in writing, which will form an integral part of it.</p> -->
                <p style="font-size: 13px !important; line-height: 16px !important;">II.- That both parties acknowledge and accept that the “Commercial Seller – 2nd Party” has been referenced and presented in commercial terms by "Commercial Director" or by another "Commercial Seller" of the 1st Party. The Commercial Seller only acquires this quality because he is invited by the Commercial Director or by another Commercial Seller, and, before all the parties, accepts and gives his consent to be bound by this contract.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">III.- That, due to the aforementioned negotiations and presentations, both parties have collaborated assiduously and maintain a better knowledge of the scope of the contractual object that is intended and therefore freely and mutually agreed to sign this service provision contract.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">IV.- That the contract for the provision of services will consist of this document and the annexes, additions and/or additional incentives that the parties decide to agree by mutual agreement and in writing that will form an integral part of it.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">They enter into an Agreement for the provision of services which, mutually subordinate to the following clauses:</p>
                
                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">1st Clause</h5>
                <p style="font-size: 13px !important; line-height: 16px !important;">The 2nd Party will provide the 1st Party with the following services:</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">a) Promote sales of DNE TOKEN within the scope of the digital asset;</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">b) Support to the 1st Party, or its customers, in the information and negotiation process with third parties for the purposes of successful sales of the DNE TOKEN, under the conditions and regulation as set out in the white paper.</p>
                <!-- <p style="font-size: 13px !important; line-height: 16px !important;">The 2nd Party will provide the 1st Party with the following services:</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">a) Promote sales of DNE TOKEN within the scope of the private phase of the digital asset;</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">b) Support the 1st Party, or its customers, in the process of information and negotiation with third parties for the purposes of successful sales of the DNE TOKEN, under the conditions and regulation as set out in the white paper.</p> -->
                
                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">2nd Clause</h5>
                <p style="font-size: 13px !important; line-height: 16px !important;">The 1st Party designates and the 2nd Party declares to accept that it has no geographical restrictions for the exercise of its activity, thus being legitimized to develop sales in any territory.</p>

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">3rd Clause</h5>
                <p style="font-size: 13px !important; line-height: 16px !important;">The services are provided by the 2nd Party autonomously and independently.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">The Commercial Seller, ie 2nd Party, will carry out its collaboration autonomously, organizing its own means and working hours that it deems most appropriate, even if it assiduously uses the means and work tools of the 1st Party or other entities that are associated with it, belonging to the same group or who regularly collaborate with it.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">The 2nd Party will be considered a supplier of the Company, therefore, in no case can it be considered integrated into its staff, nor hierarchically dependent, in any way, on other entities linked to it or its representatives / directors.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">The Commercial Seller, i.e. the 2nd Party, will not have the power to sign any type of contract, either directly or indirectly, in the name and/or on behalf of the 1st Party.</p>

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">4th Clause</h5>
                <p style="font-size: 13px !important; line-height: 16px !important;">As consideration for the contracted services, the 2nd Party will receive a consideration for the sales made, earning the following commissions:</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">a) During the private sale phase, in the following public sale phase, the 2nd Party is qualified and entitled to proceed with sales in any territory always earning 10% as a commission on direct sales of DNE Token Lots.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">b) The 2nd Party may fundraise stores and/or commercial spaces for the 1st Party's Online Shopping, always earning 5 % as a commission on the rate applicable to the participating merchant.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">Payment made by the 1st Party will always be upon presentation and delivery of a valid document - invoice/receipt. The aforementioned fees always constitute a gross amount, so any tax or banking charge will be the exclusive responsibility of the 2nd Party, including possible withholding tax, bank expenses, digital intermediary commission expenses, and others that fall within this concept. .</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">The 2nd Party will be the one who assumes, as an autonomous service provider, its obligation to contribute to Security Social Security and any other tax obligation arising from its activity and where its tax domicile is located, without such expense or charge being passed on or likely to be passed on to the Company of the 1st Party or its subsidiaries during the course of the contract or after, whether the what title it is.</p>                
                <!-- <p style="font-size: 13px !important; line-height: 16px !important;">In return for the contracted services, the 2nd Party will receive a consideration for the sales made, earning the following commissions:</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">a) During the private sale phase, the 2nd Party is qualified and authorized to proceed with sales in any territory, earning 10% as a commission on direct sales of the DNE Token Lots.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">The payment made by the 1st Party will always be against presentation and delivery of a valid document - invoice/receipt. The aforementioned fees always constitute a gross amount, so any tax or banking charge will be the exclusive responsibility of the 2nd Party, including possible withholding tax, bank expenses, digital intermediary commission expenses, and others that fall within this concept.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">The 2nd Party will be the one who, as a service provider, independently assumes its obligation to contribute to Social Security and any other tax obligation arising from its activity and where its tax domicile is located, without such expense or charge is passed on or may be passed on to the 1st Party's Company or its subsidiaries during the course of the agreement or after, for whatever reason.</p> -->

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">5th Clause</h5>
                <p style="font-size: 13px !important; line-height: 16px !important;">The Commercial Seller, read 2nd Party, acquires the category of Commercial Coordinator as soon as it makes a sales value equivalent to 180 DNE Token lots or a volume of 5 (five) consecutive sales of DNE Token Lots. After acquiring the status of Commercial Coordinator, the 2nd Party remains qualified and entitled to carry out sales in any territory, earning 10% as a commission on the direct sales of DNE Token lots, in addition to the earning of 3% as a of commission on sales made by your team of sellers of the DNE Token Lots, including your own direct sales.</p>

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">6th Clause</h5>
                <p style="font-size: 13px !important; line-height: 16px !important;">This contract is concluded for a period of 1 (one) Year, renewable for successive periods of 1 (one) Year if not is denounced by none of the parties, by means of a registered letter with acknowledgment of receipt, at least 2 (two) months before the expiry of the term or its renewal.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;"> Regardless of the right to terminate the contract within the period and under the conditions mentioned in the previous number, either party has the right to terminate this contract with just cause based on culpable non-compliance by the other party, by means of a written communication sent to the other, provided that it is done with 10 (ten) days in advance.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">Resolution with just cause operates by means of written communication, by means of a letter with acknowledgment of receipt, to the opposing party invoking the respective fundamentals.</p>
                <!-- <p style="font-size: 13px !important; line-height: 16px !important;">This agreement will only be valid during the course of the private sale phase of DNE TOKEN, which is set at three months, in force between the day <strong>??? {{ dateTime(dataContract.contractStart) }}</strong> and the end of the day <strong>??? {{ dateTime(dataContract.contractEnd) }}</strong>, therefore this contract has a maximum duration of three months and ending on day <strong>??? {{ dateTime(dataContract.contractEnd) }}</strong>, with no renewal. Both parties hereby recognize and accept that this Agreement may be terminated before the expiration if the total amount of Lots put available during this stage, corresponding to 100,000 Lots of 180 units DNE Token each are sold out.</p>
                <p style="font-size: 13px !important; line-height: 16px !important;">In the event of non-compliance with any of the clauses of the agreement and/or in the event of manifest damage or damage to the image of any of the Parties, this contract may be terminated by either Party by means of a written communication sent to the other, provided that it is done with 10 (ten) days advance notice.</p>  -->

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">7th Clause</h5>
                <p style="font-size: 13px !important; line-height: 16px !important;">The taxes and legal declarations resulting from the payment referred to in clause 4 are entirely the responsibility of the 2nd Party and borne by the same, as well as all expenses that it will have to incur for the correct performance of its functions, namely communications, accommodation, travel or car rental.</p>             

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">8th Clause</h5>
                <p style="font-size: 13px !important; line-height: 18px !important;">The 2nd Party, Commercial Seller, expressly acknowledges and accepts that the customer portfolio of the 1st Party Company, or of its subsidiaries or other entities associated with it, belong to the same group or that regularly collaborate with it (hereinafter referred to as Portfolio) is their exclusive property. That portfolio includes:</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">a) existing customers at the time of the beginning of the collaboration established between the Commercial Seller and the 1st Party Company;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">b) clients recruited or raised by the 2nd Party during the execution of this agreement as a result of their collaboration activity with the 1st Party or other entities that are associated, belong to the same group or that collaborate with it assiduously.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">The 1st Party undertakes to give access to <span style="text-decoration: underline;">HTTPS://SALES.DNETOKEN.COM</span> platform where the 2nd Party will have a digital "backoffice" account with access and availability of information and elements necessary and indispensable for the good performance of the activity as defined in this agreement. In addition, the aforementioned digital account must contain reports on a regular basis to calculate the sales commissions to be attributed as well as any other information that is considered relevant to recognize the results of the activity and characteristics of the services and products offered and their updates that are produced in due time.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">The 2nd Party expressly acknowledges and accepts that, when this agreement is ceased or terminated, it will not offer its services to the Portfolio by itself or through an intermediary person or company, and said digital backoffice account may be permanently deleted after 1 month after the cessation.</p>

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">9th Clause</h5>
                <p style="font-size: 13px !important; line-height: 18px !important;">The 2nd Party undertakes, during the term of this agreement and in the two years following its termination, to maintain total confidentiality and not to take advantage of the knowledge and information to which it has access in the exercise of its activity, related to the 1st Party, its subsidiaries, or its customers.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">The 2nd Party undertakes to maintain total secrecy and to maintain confidentiality, both with regard to the terms of this contract and in relation to all elements, information and documents that it may have access to as a result of its execution, namely those that are provided by the 1st Party or its subsidiary entities.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">The obligation of confidentiality remains, both during the execution and after the termination of the agreement for the provision of services as mentioned above.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">The 2nd Party may not use the elements, information and documents that are delivered to it by the 1st Party, or that it may have access to through third parties, for purposes other than those necessary for the proper performance of the services object of this agreement.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">Upon termination of the contract, the 2nd Party undertakes to deliver to the 1st Party the originals of documents that have been delivered to it and to destroy all copies of documents containing information to which it has had access under this agreement, unless such copies are requested by the 1st Party.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">The 2nd Party is perfectly aware of the importance of complying with the duty of confidentiality, given the nature of the functions performed by the 1st Party and its subsidiaries, so the violation of the provisions of this clause constitutes a serious breach of this agreement, giving the 1st Party the right to demand from the 2nd Party the reimbursement of all property and non-property damages resulting from such non-compliance.</p>

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">10th Clause</h5>
                <p style="font-size: 13px !important; line-height: 18px !important;">The assignment of the contractual position of the 2nd Party is prohibited.</p>

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">11th Clause</h5>
                <p style="font-size: 13px !important; line-height: 18px !important;">The protection of individuals in relation to the processing of personal data is a fundamental right.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">The principles and rules on the protection of individuals in relation to the processing of their personal data should respect, irrespective of their nationality or place of residence, their fundamental rights and freedoms, in particular the right to the protection of personal data.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">Considering that the services to be provided by the 2nd Party within the scope of this agreement presuppose that in certain cases it proceeds to the collection and processing of personal data of the 1st Party's customers (namely, name, place of birth, marital status, address, e-mail, telephone contacts or other communication data, tax and civil identification data), the 2nd Party undertakes to comply, in relation to this matter, with the following obligations, without prejudice to others arising from Regulation 2016/679 of the European Parliament and of the Council (General Regulation on Data Protection, hereinafter abbreviated as RGPD) and other national or community legislation on the same matter:</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">- carry out the collection and processing of personal data in accordance with the instructions of the 1st Party, and use such data only for the purpose provided for in this agreement;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">- apply the technical and organizational measures that are appropriate to ensure and be able to prove that the processing of such personal data is carried out in accordance with the RGPD, and that ensure the defense of the rights of the data subject, reviewing and updating these measures whenever necessary and complying with the codes of conduct and approved certification procedures that are applicable in view of its activity;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">- process the data only and only for the purpose of providing the 1st Party with the services object of this agreement;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">- ensure that the persons authorized to process personal data within its organizational structure (workers, collaborators or others) have assumed a confidentiality commitment or are subject to appropriate legal confidentiality obligations;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">- provide the 1st Party with all the information necessary for it to demonstrate compliance with its obligations under the RGPD, if necessary, and facilitate and contribute to audits, including inspections, conducted by it or by another auditor mandated by it;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">- provide the 1st Party with information and assistance, through appropriate technical and organizational measures, that allow it to comply with its obligation to respond to requests from data subjects with a view to exercising their rights;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">- inform the 1st Party immediately after becoming aware of the occurrence of a personal data breach and take the initiative to perform all necessary acts to mitigate the damage suffered by the holders of the personal data subject to the breach;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">- return to the 1st Party all personal data after completion of the provision of services object of this agreement, erasing existing copies, unless the conservation of data is required by legal obligation.</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">The personal data contained in this agreement, namely regarding the name of the representatives of the Parties, and others exchanged between the parties to allow the execution of this contract (names, place of birth, marital status, addresses, e-mails, telephone contacts or other communication data, fiscal and civil identification data), will be processed for the purposes of administrative, fiscal and accounting management of the Parties.</p>

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">12th Clause</h5>
                <p style="font-size: 13px !important; line-height: 18px !important;">All communications between the parties must be made in writing, through:</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">a) registered letter with acknowledgment of receipt, to the addresses listed in the header of this contract or others that the parties communicate later for this purpose;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">b) communication delivered by hand, with the addressee to affix a copy of his signature, with receipt;</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">c) by electronic mail, provided that, in this case, a document proving receipt (delivery receipt) is provided, to the addresses indicated below:</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">1st Party: dne@dnetoken.com</p>
                <p style="font-size: 13px !important; line-height: 18px !important;">2nd Party: {{dataContract.email}}</p>

                <h5 style="text-align: center; color: #1f3561; margin-top: 30px !important">12th Clause</h5>
                <p style="font-size: 13px !important; line-height: 18px !important; margin-bottom: 20px !important">In the resolution of doubts arising from the fulfillment of this Agreement, the provisions in force on Service Provision Agreements will be applied and the parties elect as competent the jurisdiction of the Courts of Lugano, Switzerland, with express waiver of any other. Read, understood and accepted by both Parties.</p>
                
                <p style="font-size: 13px !important; line-height: 18px !important;">This agreement will be registered at IFPS Blockchain, available after accepted.</p>
                
                <p style="font-size: 13px !important; line-height: 18px !important; margin-bottom: 30px !important"><strong>Lugano, {{ dateTime(dataContract.contractStart) }}</strong></p>
            </div>
            
            

            <img src="base64 image or url">

            <!-- You can loop to display page number as you want -->
            <div class="html2pdf__page-number">1</div>

            <!-- Break page pdf -->
            <div class="html2pdf__page-break"></div>

        </vue3-simple-html2pdf>

        <div @click="generateReport()" class="download-pdf" v-if="showContent && !errorsGet">PDF</div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    import moment from 'moment';
    import apiSales from '@/config/apiSales.js'

    import Vue3SimpleHtml2pdf from 'vue3-simple-html2pdf/src/vue3-simple-html2pdf'

    export default {
        props: {
            index: {
                type: Number,
                default: 1,
            },
            options:{
                default: {
                    margin: 15,
                    image: {
                        type: 'jpeg',
                        quality: 1,
                    },
                    html2canvas: { scale: 3 },
                }
            }
        },
        
        components:{
            Vue3SimpleHtml2pdf
        },

        data(){
            return{
                dataContract: [],
                exportFilename: [],
                showContent: false,
                errorsGet: ""
            }
        }, 

        async mounted(){
            await apiSales.get('/api/v1/agent/contract/' + this.$route.params.registerId)
            .then(response => {
                this.dataContract = response.data
                this.errorsGet = false

                this.exportFilename = "Contract_Seller_" + this.dataContract.fullName

                setTimeout(() => {
                    this.showContent = true
                }, 500);
            })
            .catch(error => {
                this.errorsGet = true
                console.log(error)
                
                setTimeout(() => {
                    this.showContent = true
                }, 500);
            })
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            generateReport () {
                this.$refs.vue3SimpleHtml2pdf.download()
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            // document.title = "DNE International > Invoice";
            // document.head.querySelector('meta[name=description]').content = ''
            // document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
            // document.head.querySelector('meta[name=viewport]').content = ''
        }
    }
</script>

<style src="./custom-invite-contract.scss" lang="scss" scoped />