import { createRouter, createWebHistory } from 'vue-router'
import Customers from '@/pages/Customers/Customers.vue'
import Leads from '@/pages/Leads/Leads.vue'
import Agents from '@/pages/Agents/Agents.vue'
import Sales from '@/pages/Sales/Sales.vue'
import SalesForce from '@/pages/Sales/SalesForce.vue'

import Invites from '@/pages/Invites/Invites.vue'
import Invite from '@/pages/Activity/Invite.vue'
import InviteContract from '@/pages/Activity/InviteContract.vue'
import InviteSuccess from '@/pages/Activity/ActivateSuccess.vue'

import NotFound from '@/pages/NotFound/NotFound.vue'
import Page403 from '@/pages/NotFound/Page403.vue'

import Login from '@/pages/Login/Login.vue'
import Profile from '@/pages/Seller/Profile.vue'
import Manuals from '@/pages/Seller/Manuals.vue'
import Report from '@/pages/Seller/Report.vue'

import Dashboard from '@/pages/Dashboard/Dash.vue'
import DashAgent from '@/pages/Dashboard/Dash.vue'
import AgentSign from '@/pages/Dashboard/AgentSign.vue'

const routes = [
  { path: '/', redirect: '/en/login/' },
  { path: '/ar', redirect: '/ar/login' },
  { path: '/en', redirect: '/en/login' },
  { path: '/es', redirect: '/es/login' },
  { path: '/pt', redirect: '/pt/login' },
  { path: '/ru', redirect: '/ru/login' },
  
  { path: '/:lang/agents/customers', name: 'Customers', component: Customers },
  { path: '/:lang/agents/leads', name: 'Leads', component: Leads },
  { path: '/:lang/agents', name: 'Agents', component: Agents },
  
  { path: '/:lang/sales', name: 'Sales', component: Sales },
  { path: '/:lang/sales/sales-force', name: 'SalesForce', component: SalesForce },
  
  { path: '/:lang/invites/', name: 'Invites', component: Invites },
  { path: '/:lang/agent/invite/:registerId', name: 'Invite', component: Invite },
  { path: '/:lang/agent/invite-contract/:registerId', name: 'InviteContract', component: InviteContract },
  { path: '/:lang/agent/invite/success', name: 'InviteSuccess', component: InviteSuccess },
  
  { path: '/:lang/login', name: 'Login', component: Login },
  { path: '/:lang/seller/profile/', name: 'Profile', component: Profile },
  { path: '/:lang/seller/manuals/', name: 'Manuals', component: Manuals },
  { path: '/:lang/seller/report/', name: 'Report', component: Report },

  { path: '/:lang/dashboard/', name: 'Dashboard', component: Dashboard },
  { path: '/:lang/dashboard/:registerId', name: 'DashAgent', component: DashAgent },
  { path: '/:lang/dashboard/agent-sign', name: 'AgentSign', component: AgentSign },
  
  { path: '/:lang/page403', name: 'Page403', component: Page403 },

  { path: '/:lang/:catchAll(.*)', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
