<template>
    <div class="leads">
        <Header />

        <div class="content">
            <h3 class="title-secondary" v-if="!filter">
                {{ getMyLanguage("leads", "leads.title") }}
                <small v-if="nameCustomersFilter">{{nameCustomersFilter}}</small>
            </h3>

            <div class="box-error" v-if="errorsGet.dataLead != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                <!-- <div class="cod-error">
                    Cód.: {{errorsGet.dataLead}}
                </div> -->
            </div>

            <div v-if="!errorsGet.dataLead">

                <div class="load" v-if="lengthCustomers === 'load'"></div>

                <div class="search-bar" v-if="lengthCustomers === true && !filter">
                    <input type="text" class="form form-search" id="searchCustomer" v-on:keyup="filterList" :placeholder='getMyLanguage("customers", "customers.search")'>
                    <div class="btn-filter" @click="filter = true"></div>
                    <div class="btn-back" v-if="showBack" onclick="history.back()"></div>
                </div>
                
                <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                <div class="filter box" v-if="filter">
                    <div class="close-filter" @click="filter = false"></div>

                    <div class="filters">
                        <div class="form-group-switch">
                            <input type="checkbox" v-model="acceptWithdraw" name="" id="acceptWithdraw" class="form-switch">
                            <label for="acceptWithdraw" class="form-switch-label">
                                <small>Filter name</small>
                            </label>
                        </div>
                    </div>

                    <div class="btn-primary">Filter</div>
                </div>

                <div class="box" v-if="lengthCustomers === true">
                    <div class="scroll-horizontal">
                        <table class="list-result" id="list">
                            <thead class="header-list">
                                <tr>
                                    <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                    <th class="text-left">{{ getMyLanguage("list-result","name") }}</th>
                                    <th class="fullName text-left">{{ getMyLanguage("list-result","agent") }}</th>
                                    <th class="country">{{ getMyLanguage("list-result","country") }}</th>
                                    <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                    <th class="invite">{{ getMyLanguage("list-result","invite") }}</th>
                                </tr>
                            </thead>

                            <tbody id="drop-list">
                                <tr v-for="(leadItem,index) in dataLead" :key="index" class="list">
                                    <td class="date" @click="openLead(leadItem,index)"><span>{{ dateTimeMin(leadItem.lead_created_at) }}</span></td>
                                    <td class="text-left" @click="openLead(leadItem,index)"><span>{{leadItem.lead_full_name}}</span></td>
                                    <td class="fullName" @click="openLead(leadItem,index)"><span>{{leadItem.agent_full_name}}</span></td>
                                    <td class="country" @click="openLead(leadItem,index)"><span>{{leadItem.country_name}}</span></td>
                                    <td class="status tooltip" :class="leadItem.lead_status" @click="openLead(leadItem,index)">
                                        <span>{{ getMyLanguage("status", leadItem.lead_status) }}</span>
                                        <div class="tooltip">{{ getMyLanguage("leads","tooltip." + leadItem.lead_status) }}</div>
                                    </td>
                                    <td class="send-invite">
                                        <span v-if="!leadItem.lead_invite_id" @click="openInvite(leadItem.lead_full_name,leadItem.lead_type_code,leadItem.lead_id,leadItem.lead_invite_id)"><img src="~@/assets/images/icons/lead-send-invite.svg" :alt="getMyLanguage('list-result','invite')"></span>
                                        <span v-if="leadItem.lead_invite_id" @click="openLead(leadItem,index)"><img src="~@/assets/images/icons/view.svg" ></span>
                                        
                                        <!-- <small v-if="leadItem.lead_status != 'NotQualified' && leadItem.lead_status != 'InQualifying'">- -</small> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="no-results" v-if="lengthCustomers === false">
                    <p>{{ getMyLanguage("leads", "no-leads") }}</p>
                    <div class="btn-back" onclick="history.back()"></div>
                </div>
            </div>
        </div>


        <!-- MODAL LEAD -->
        <div class="modal modal-lead" v-if="modalLead.show">
            <div @click="modalLead.show = false" class="close-modal"></div>

            <div class="box cont-modal has-scroll">
                <div class="modal-scroll">
                    
                    <div class="load" v-if="modalLead.load"></div>

                    <div class="complete-lead" v-if="!modalLead.load">
                        <div class="box" :class="modalLead.cont.lead_status">
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.date") }}:</span> <strong v-html="dateTimeFull(modalLead.cont.lead_created_at)"></strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.pep") }}:</span> <strong>{{ getMyLanguage("modal","modal.lead-description.pep." + modalLead.cont.lead_pep) }}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.source") }}:</span> <strong>{{modalLead.cont.lead_source}}</strong></div>
                            <div v-if="modalLead.cont.referrer_account_full_name"><span>{{ getMyLanguage("modal", "modal.lead-description.referrer.name") }}:</span> <strong>{{modalLead.cont.referrer_account_full_name}}</strong></div>
                            <div v-if="modalLead.cont.referrer_account_id"><span>{{ getMyLanguage("modal", "modal.lead-description.referrer.id") }}:</span> <strong>{{modalLead.cont.referrer_account_id}}</strong></div>
                            
                            <div class="divider"></div>
                            
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.name") }}:</span> <strong>{{modalLead.cont.lead_full_name}}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.agent") }}:</span> <strong>{{modalLead.cont.agent_full_name}}</strong></div>
                            
                            <div class="divider"><span>{{ getMyLanguage("modal", "modal.lead-description.status") }}:</span> <strong class="statusModal" :class="modalLead.cont.lead_status">{{ getMyLanguage("status", modalLead.cont.lead_status) }}</strong></div>
                            
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.email") }}:</span> <strong>{{modalLead.cont.lead_email}}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.phone") }}:</span> <strong>+{{modalLead.cont.country_ddi}} {{modalLead.cont.lead_phone_number}}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.type") }}:</span> <strong>{{ getMyLanguage("leads","type." + modalLead.cont.lead_type_code )}}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.language") }}:</span> <strong>{{modalLead.cont.language_name}}</strong></div>
                            <div><span>{{ getMyLanguage("modal", "modal.lead-description.region") }}:</span> <strong>{{modalLead.cont.country_name}} ({{modalLead.cont.region_name}})</strong></div>
                        </div>

                        <div class="box match" v-if="!modalLead.load && modalLead.cont.account_id">
                            <p>{{ getMyLanguage("modal", "modal.lead-description.match") }} <strong>{{ modalLead.cont.account_full_name }}</strong> {{ getMyLanguage("modal", "modal.lead-description.referrerw") }} {{ modalLead.cont.referrer_account_full_name }}</p>
                        </div>

                        <div class="box has-invite" v-if="modalLead.cont.lead_invite_id">
                            <h3 class="title-secondary">{{ getMyLanguage("menu", "modalInvite.title") }}</h3>
                            <label>
                                <!-- {{ getMyLanguage("register", "register.label.share") }} -->
                                
                                <div class="share" @click="copyShare(`${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/invite/${modalLead.cont.lead_invite_id}`)">
                                    <span>{{`${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/invite/${modalLead.cont.lead_invite_id}`}}</span>
                                </div>
                            </label>
                        </div>

                        <div class="box form-lead">
                            <label>
                                {{ getMyLanguage("modal", "modal.lead-description.agent") }}
                                <select class="form" v-model="leadPost.agent_id">
                                    <option v-for="(listAgent,index) in dataAgents" :key="index" :value="listAgent.id">{{listAgent.name}}</option>
                                </select>
                            </label>

                            <label>
                                {{ getMyLanguage("modal", "modal.lead-description.status") }}
                                <select class="form" v-model="leadPost.lead_status_id">
                                    <option v-for="(listStatus,index) in dataStatuslead" :key="index" :value="listStatus.Id">{{ getMyLanguage("status", listStatus.Value) }}</option>
                                </select>
                            </label>

                            <label class="lbl-cont">
                                {{ getMyLanguage("modal", "modal.lead-description.reason") }}
                                <small class="cont">{{ leadPost.lead_status_reason ? leadPost.lead_status_reason.length : 0 }}/50</small>
                                <textarea class="form" maxlength="50" v-model="leadPost.lead_status_reason"></textarea>
                            </label>

                            <div class="btn-primary btn-disable" v-if="!leadPost.agent_id">{{ getMyLanguage("register","register.btn-save") }}</div>
                            <div class="btn-primary" v-if="leadPost.agent_id" @click="saveLead()">{{ getMyLanguage("register","register.btn-save") }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modalLead.show"></div>


        <!-- MODAL INVITE -->
        <div class="modal modal-invite" v-if="modalInvite.show">
            <div @click='(modalInvite.show = false),(typeInvite = "")' class="close-modal"></div>
            <div class="box cont-modal">
                <h3 class="title-secondary">{{ getMyLanguage("menu", "modalInvite.title") }}</h3>

                <div class="load" v-if="modalInvite.load"></div>

                <div class="box has-invite" v-if="!modalInvite.load">
                    <label>
                        {{ getMyLanguage("menu","modalInvite.invitationID") }}
                        <input type="text" class="form" v-model="description" :placeholder='getMyLanguage("menu","modalInvite.invitation-placeholder")'>
                    </label>

                    <div class="bts-invite">
                        <div class="btn btn-disable" v-if="description.length < 5">{{getMyLanguage("menu","modalInvite.generate.invite")}}</div>
                        <div class="btn active" v-if="description.length >= 5" @click='newInvite()'>{{getMyLanguage("menu","modalInvite.generate.invite")}}</div>
                    </div>

                    <div v-if="!modalInvite.load && dataInvite.hasInvite">
                        <div class="qtd-invite">
                            <div>{{ getMyLanguage("menu", "modalInvite.used") }}: {{dataInvite.used}}</div>
                            <div>{{ getMyLanguage("menu", "modalInvite.remaining") }}: {{dataInvite.remaining}}</div>
                        </div>

                        <label>
                            {{ getMyLanguage("register", "register.label.share") }}
                            <div class="share" @click="copyShare(dataInvite.link)"><span>{{dataInvite.link}}</span></div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modalInvite.show"></div>
        
    </div>
</template>

<script>
    // const $ = require('jquery')
    // window.$ = $

    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';

    import apiSales from '@/config/apiSales.js'
    import apiPlatform from '@/config/apiPlatform.js'

    import moment from 'moment';
    import {mask} from 'vue-the-mask'

    export default {
        components: {
            Header
        },
        
        directives: {mask},

        data () {
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                dataLead: [],

                lengthCustomers: "load",

                nameCustomersFilter: false,
                showBack: false,

                flagSelected: {},
                filter: false,

                errorsGet: {
                    dataLead: false,
                    dataInvite: false
                },

                modalLead: {
                    load: false,
                    show: false,
                    cont: {},
                    index: ""
                },
                
                dataInvite: [],
                modalInvite: {
                    lead_id: "",
                    load: true,
                    show: false,
                    invite: false,
                },
                
                dataStatuslead: [],
                dataAgents: [],

                leadPost: {
                    agent_id: "",
                    lead_status_id: "",
                    lead_status_reason: ""
                },
                
                description: "",
                typeInvite: {}
            }
        },

        async mounted () {
            await apiSales.get('/api/v1/platform/lead/followups')
            .then(response => {
                this.dataLead = response.data

                if ( this.dataLead.length > 0 ){
                    this.lengthCustomers = true
                } else{
                    this.lengthCustomers = false
                }
            })
            .catch(error => {
                console.log(error)
                this.errorsGet.dataLead = true

                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }

                if ( this.dataLead.length > 0 ){
                    this.lengthCustomers = true
                } else{
                    this.lengthCustomers = false
                }
            })


            // STATUS
            const statusLead = await apiPlatform.get('/api/v1/public/attributes/codes/lead_status')
            this.dataStatuslead = statusLead.data

            
            // AGENT
            const allAgents = await apiSales.get('/api/v2/sales/agent/agents')
            this.dataAgents = allAgents.data

            this.leadPost.agent_id = this.dataAgents.filter(ag => ag.accountId == this.dataLead.agent_account_id).accountId
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY-MM-DD' : 'DD-MM-YYYY';
                return moment(value).format(format);
            },

            dateTimeMin(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchCustomer");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            openLead(value,index){
                this.modalLead.load = true
                this.modalLead.cont = value
                this.modalLead.show = true
                this.modalLead.index = index

                this.leadPost.agent_id = this.dataAgents.filter(ag => ag.agentId == this.modalLead.cont.agent_id)[0].agentId
                this.leadPost.lead_status_id = this.dataStatuslead.filter(st => st.Id == this.modalLead.cont.lead_status_id)[0].Id
                this.leadPost.lead_status_reason = this.modalLead.cont.lead_status_reason

                setTimeout(() => {
                    this.modalLead.load = false
                }, 500);
            },

            saveLead(){
                this.modalLead.load = true

                apiSales.post('api/v1/platform/lead/followups/' + this.modalLead.cont.lead_id, this.leadPost)
                .then(response => {                    
                    this.dataLead[this.modalLead.index] = response.data

                    setTimeout(() => {
                        this.modalLead.load = false
                        this.modalLead.show = false

                        this.$toast.success(this.getMyLanguage("leads","message-toast.success"));
                    }, 500);
                })
                .catch(error => {
                    console.log(error)
                    this.modalLead.load = false
                    // this.modalLead.show = false

                    this.$toast.error(this.getMyLanguage("box-error","msg-error"));
                })
            },

            async copyShare(urlShare) {
                try {
                await navigator.clipboard.writeText(urlShare);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                this.$toast.success(this.getMyLanguage("leads","message-toast.copied"));
                setTimeout(this.$toast.clear, 6000)
            },            
            
            async openToastNocopy() {
                this.$toast.success(this.getMyLanguage("leads","message-toast.noCopied"));
                setTimeout(this.$toast.clear, 6000)
            },
            

            // INVITE
            openInvite(name,type,leadId,inviteId){
                this.modalInvite.load = true
                this.modalInvite.show = true
                this.modalInvite.lead_id = leadId
                this.modalInvite.lead_invite_id = inviteId
                
                this.description = name

                if ( type == "person" ){
                    this.typeInvite = "account_invite"
                } else {
                    this.typeInvite = "company_invite"
                }
                
                this.showContent = true
                this.dataInvite.hasInvite = false

                setTimeout(() => {
                    this.modalInvite.load = false
                }, 500);
            },

            async newInvite(){
                this.showContent = false

                await apiSales.get('/api/v1/platform/account/invite/' + this.typeInvite + '?description=' + this.description + '?lead_id=' + this.modalInvite.lead_id)
                .them(response => {
                    this.dataInvite = response.data
                    this.showContent = true
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error(this.getMyLanguage("box-error","msg-error"));
                })
                
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-leads.scss" lang="scss" scoped />