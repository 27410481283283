<template>
    <div>
        <header class="header" :class="{develop: VUE_APP_DEVELOP == 'develop'}">
            <div class="header-row">
                <div class="logo-col">
                    <div class="logo-dne" @click="lnkDash()">
                        <img src="@/assets/images/dne.svg">
                    </div>
                </div>

                <div class="welcome-col">
                    <div class="welcome-seller">
                        <div class="welcome">
                            <h4>{{$store.state.agent.name}}</h4>
                            <p>{{ getMyLanguage("status", $store.state.agent.agentTypeCode) }}</p>
                        </div>
                    </div>
                </div>

                <div class="menu-col">
                    <SelectLanguage />

                    <div class="open-menu" v-if="!menu" @click="menu = true">
                        <img src="~@/assets/images/icons/menu.svg">
                    </div>
                </div>
            </div>

            <div class="menu" v-if="menu">
                <div class="close-menu" v-if="menu" @click="menu = false">
                    <img src="~@/assets/images/icons/menu-close.svg">
                </div>
                
                <a :href="'/' + flagSelected + '/dashboard'" class="lnk lnk-dashboard"><span>{{ getMyLanguage("menu", "dashboard") }}</span></a>
                <a :href="'/' + flagSelected + '/invites'" class="lnk lnk-invites"><span>{{ getMyLanguage("menu", "invites") }}</span></a>
                
                <div @click="openInvite()" class="lnk lnk-new-invite"><span>{{ getMyLanguage("menu", "new-invite") }}</span></div>
                
                <a :href="'/' + flagSelected + '/agents/customers'" class="lnk lnk-customers"><span>{{ getMyLanguage("menu", "customers") }}</span></a>
                <a :href="'/' + flagSelected + '/agents/leads'" class="lnk lnk-leads"><span>{{ getMyLanguage("menu", "leads") }}</span></a>
                <a :href="'/' + flagSelected + '/sales'" class="lnk lnk-sales"><span>{{ getMyLanguage("menu", "sales") }}</span></a>
                <a :href="'/' + flagSelected + '/seller/profile'" class="lnk lnk-profile"><span>{{ getMyLanguage("menu", "profile") }}</span></a>
                <a :href="'/' + flagSelected + '/seller/manuals'" class="lnk lnk-manuals"><span>{{ getMyLanguage("menu", "manuals") }}</span></a>

                <div class="menu-director" v-if='$store.state.agent.agentTypeCode === "regional_director" || $store.state.agent.agentTypeCode === "master_franchising"'>
                    <a :href="'/' + flagSelected + '/sales/sales-force'" class="lnk lnk-sales-force"><span>{{ getMyLanguage("menu", "sales-force") }}</span></a>
                    <a :href="'/' + flagSelected + '/agents'" class="lnk lnk-agents"><span>{{ getMyLanguage("menu", "agents") }}</span></a>
                    <a :href="'/' + flagSelected + '/seller/report'" class="lnk lnk-report"><span>{{ getMyLanguage("menu", "report") }}</span></a>
                </div>

                <div class="logout">
                    <div @click="logoutPlatform()" class="lnk lnk-logout"><span>{{ getMyLanguage("menu", "logout") }}</span></div>
                </div>
            </div>
            
            <div class="overlaymenu" v-if="menu" @click="menu = false"></div>
        </header>


        <!-- MODAL INVITE -->
        <div class="modal" v-if="modalInvite.modal">
            <div @click='(modalInvite.modal = false),(typeInvite = "")' class="close-modal"></div>
            <div class="box cont-modal">
                <h3 class="title-secondary">{{ getMyLanguage("menu", "modalInvite.title") }}</h3>

                <div class="load" v-if="!showContent"></div>

                <!-- HAS INVITE -->
                <div class="box has-invite" v-if="showContent">
                    <label>
                        {{ getMyLanguage("menu","modalInvite.invitationID") }}
                        <input type="text" class="form" v-model="description" :placeholder='getMyLanguage("menu","modalInvite.invitation-placeholder")'>
                    </label>
                        
                    <div class="bts-invite" v-if="description.length < 5">
                        <div class="btn btn-disable">{{getMyLanguage("menu","modalInvite.generate.customer")}}</div>
                        <div class="btn btn-disable">{{getMyLanguage("menu","modalInvite.generate.company")}}</div>
                    </div>

                    <div class="bts-invite" v-if="description.length >= 5">
                        <div class="btn" :class='{active: typeInvite === "account_invite"}' @click='(typeInvite = "account_invite"),(modalInvite.invite),(newInvite(typeInvite))'>{{getMyLanguage("menu","modalInvite.generate.customer")}}</div>
                        <div class="btn" :class='{active: typeInvite === "company_invite"}' @click='(typeInvite = "company_invite"),(modalInvite.invite),(newInvite(typeInvite))'>{{getMyLanguage("menu","modalInvite.generate.company")}}</div>
                    </div>

                    <div v-if="showContent && dataInvite.hasInvite">
                        <div class="qtd-invite">
                            <div>{{ getMyLanguage("menu", "modalInvite.used") }}: {{dataInvite.used}}</div>
                            <div>{{ getMyLanguage("menu", "modalInvite.remaining") }}: {{dataInvite.remaining}}</div>
                        </div>

                        <label>
                            {{ getMyLanguage("register", "register.label.share") }}
                            <div class="share" @click="copyShare(dataInvite.link)"><span>{{dataInvite.link}}</span></div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlayModal" v-if="modalInvite.modal"></div>
    </div>
</template>

<script>
    // console.log(process.env)

    import getMyFavouriteLanguage from "@/services/language.js";

    import SelectLanguage from '@/components/SelectLanguage.vue'

    import apiSales from '@/config/apiSales.js';

    export default {
        components: {
            SelectLanguage
        },

        data () {
            return {
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,

                load: true,
                flagSelected: {},
                menu: false,

                showContent: false,
                dataInvite: [],
                
                modalInvite: {
                    modal: false,
                    invite: false,
                },
                
                description: "",
                typeInvite: ""
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = this.flagSelected + '/login/'
                }
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            logoutPlatform(){
                localStorage.removeItem('accessToken')
                window.location.href = this.flagSelected + '/login/'
            },

            openInvite(){
                this.menu = false
                this.modalInvite.modal = true
                this.showContent = false
                this.description = ""
                this.dataInvite.hasInvite = false

                setTimeout(() => {
                    this.showContent = true
                }, 500);
            },

            async newInvite(){
                this.showContent = false
                this.menu = false

                const resumeInvite = await apiSales.get('/api/v1/platform/account/invite/' + this.typeInvite + '?description=' + this.description)
                this.dataInvite = resumeInvite.data

                this.showContent = true
            },

            async copyShare(urlShareInvite) {
                try {
                await navigator.clipboard.writeText(urlShareInvite);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                this.$toast.success(this.getMyLanguage("leads","message-toast.copied"));
                setTimeout(this.$toast.clear, 6000)
            },
            
            
            async openToastNocopy() {
                this.$toast.success(this.getMyLanguage("leads","message-toast.noCopied"));
                setTimeout(this.$toast.clear, 6000)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style src="@/assets/scss/_header.scss" lang="scss" scoped />