<template>
    <div class="invite">
        <div class="content">
            
            <div class="load" v-if="load"><span></span></div>

            <div class="box-error" v-if="!load && errorsGet.dataInvite != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", errorsGet.dataInvite) }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataInvite}}
                </div>
            </div>
            <div class="overlay-error" v-if="!load && errorsGet.dataInvite != false"></div>
            
            <div class="content-activate" v-if="!load && !errorsGet.dataInvite">
                <div class="logo-dne-activate">
                    <img src="@/assets/images/logo-dne.svg">
                </div>

                <h3 class="title-secondary">{{ getMyLanguage("activate","activate.title") }}</h3>

                <div class="twoColumns">
                    <div class="col-activate col-contract">
                        <div class="terms-contract">
                            <iframe :src='urlContract' frameborder="0"></iframe>

                            <div class="form-group-check">
                                <input type="checkbox" id="acceptReadAccept" class="form-check" v-model="accept.readAccept">
                                <label for="acceptReadAccept" class="form-check-label"><small>{{ getMyLanguage("activate","activate.read-accept") }}</small></label>
                            </div>
                        </div>
                    </div>

                    <div class="col-activate col-data">

                        <div class="box">
                            <p><strong>{{ getMyLanguage("profile","profile.label.regionName") }}: </strong>{{ getMyLanguage("status", dataInvite.region) }}</p>
                            <p><strong>{{ getMyLanguage("profile","profile.label.agentType") }}: </strong>{{ getMyLanguage("status", dataInvite.agentType) }}</p>
                        </div>
                        
                        <div class="box-registration">
                            <div class="formBox">
                                <h5 class="msg-activate">{{ getMyLanguage("activate","activate.alert") }}</h5>
                                <label class="formFullName">
                                    <input v-model="newRecruit.fullName" type="text" class="form" :class="{error: errorsForm.fullName.empty || errorsForm.fullName.incomplete}" @blur="(errorsForm.fullName.empty = false), (errorsForm.fullName.incomplete = false)" :placeholder='this.getMyLanguage("register", "agent.label.fullName")' maxlength="80" autocomplete="off" >
                                </label>
                                <label class="formYourEmail">
                                    <input v-model="newRecruit.email" type="email" :class="{error: errorsForm.email.empty || errorsForm.email.invalid || errorsForm.email.inUse}" @blur="(errorsForm.email.empty = false), (errorsForm.email.invalid = false), (errorsForm.email.inUse = false)" id="email" class="form formYourEmail" :placeholder='this.getMyLanguage("register", "agent.label.email")'  maxlength="60" autocomplete="off">
                                </label>

                                <div class="autocomplete formResidenceCountry">
                                    <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus === true" v-on:click="dropListCountry(false)"></div>
                                    <input type="text" id="residenceCountry" class="form" :class="{error: errorsForm.country.empty}" @blur="(errorsForm.country.empty = false)" @focus="dropListCountry(true)" @click="dropListCountry(true)" v-on:keyup="filterList" :value="selectedCountry.name" :placeholder='this.getMyLanguage("register", "agent.label.residenceCountry")' title="Residence country" autocomplete="off">
                                    <ul id="drop-list" v-if="listCountryStatus === true">
                                        <li v-for="(countryItem,index) in dataCountry" :key="index" v-on:click="(newRecruit.countryId = countryItem.Id), (newRecruit.phoneCountryId = countryItem.Id), (this.changeMask()), (selectedCountryDDI.countrySelected = true), (selectedCountryDDI.active = 'active'), (selectedCountryDDI.phoneMask = countryItem.PhoneMask), (selectedCountryDDI.ddi = countryItem.DDI), (selectedCountryDDI.flag = countryItem.Flag), (selectedCountry.name = countryItem.Name), (dropListCountry(false))">
                                            <img :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + countryItem.Flag + "/flags"'>
                                            <a>{{countryItem.Name}}</a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="formPhone"  :class="{error: errorsForm.phoneNumber.empty || errorsForm.phoneNumber.invalid || errorsForm.phoneNumber.inUse}" @blur="(errorsForm.phoneNumber.empty = false), (errorsForm.phoneNumber.invalid = false), (errorsForm.phoneNumber.inUse = false)">
                                    <div class="select-ddi" v-if="selectedCountryDDI.countrySelected === true && selectedCountryDDI.changeMask === true">
                                        <div class="open-select-ddi" @click="selectedCountryDDI.openSelect = true"></div>
                                        <div class="close-select-ddi" v-if="selectedCountryDDI.openSelect === true" @click="selectedCountryDDI.openSelect = false"></div>
                                        <div class="selected-ddi">
                                            <div class="flag">
                                                <img :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + selectedCountryDDI.flag + "/flags"' v-if="selectedCountryDDI.flag != ''">
                                            </div>
                                            <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                        </div>
                                        <ul class="list-ddi" v-if="selectedCountryDDI.openSelect === true">
                                            <li v-for="(ddiList,index) in dataCountry" :key="index" @click="(newRecruit.phoneCountryId = ddiList.Id), (selectedCountryDDI.phoneMask = ddiList.PhoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.DDI), (selectedCountryDDI.flag = ddiList.Flag), (selectedCountryDDI.openSelect = false)">
                                                <div class="flag">
                                                <img class="anime" :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + ddiList.Flag + "/flags"'>
                                                </div>
                                                <span class="name-country">{{ddiList.Name}}</span>
                                                <span class="ddi" >(+{{ddiList.DDI}})</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <label v-if="selectedCountryDDI.changeMask === true">
                                        <input v-model="newRecruit.phoneNumber" id="phoneNumber" type="text" class="form" v-mask="selectedCountryDDI.phoneMask" :placeholder='this.getMyLanguage("register", "agent.label.phoneNumber")' maxlength="25" autocomplete="off" >
                                    </label>
                                </div>


                                <h5 class="msg-activate">{{getMyLanguage("activate","activate.alert-password")}}</h5>

                                <div class="enterPass">
                                    <label class="formPassword">
                                        <input v-model="newRecruit.password" type="password" v-if="!showPass" class="form" :class="{error: errorsForm.password.empty || errorsForm.password.incomplete}" @blur="(errorsForm.password.empty = false), (errorsForm.password.incomplete = false)" :placeholder='this.getMyLanguage("register", "agent.label.password")' maxlength="16" autocomplete="off" >
                                        <input v-model="newRecruit.password" type="text" v-if="showPass" class="form" :class="{error: errorsForm.password.empty || errorsForm.password.incomplete}" @blur="(errorsForm.password.empty = false), (errorsForm.password.incomplete = false)" :placeholder='this.getMyLanguage("register", "agent.label.password")' maxlength="16" autocomplete="off" >
                                    </label>

                                    <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                    <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                </div>

                                <div class="msgError">
                                    <!-- {{errorsForm}} -->
                                    <span v-if="errorsForm.fullName.empty">{{ getMyLanguage("errors", "errors.fullNameError.empty") }}</span>
                                    <span v-if="errorsForm.fullName.incomplete">{{ getMyLanguage("errors", "errors.fullNameError.incomplete") }}</span>
                                    <span v-if="errorsForm.email.empty">{{ getMyLanguage("errors", "errors.emailError.empty") }}</span>
                                    <span v-if="errorsForm.email.invalid">{{ getMyLanguage("errors", "errors.emailError.invalid") }}</span>
                                    <span v-if="errorsForm.email.inUse">{{ getMyLanguage("errors", "errors.emailError.inUse") }}</span>
                                    <span v-if="errorsForm.country.empty">{{ getMyLanguage("errors", "errors.countryError.empty") }}</span>
                                    <span v-if="errorsForm.phoneNumber.empty">{{ getMyLanguage("errors", "errors.phoneError.empty") }}</span>
                                    <span v-if="errorsForm.phoneNumber.invalid">{{ getMyLanguage("errors", "errors.phoneError.invalid") }}</span>
                                    <span v-if="errorsForm.phoneNumber.inUse">{{ getMyLanguage("errors", "errors.phoneError.inUse") }}</span>
                                    <span v-if="errorsForm.password.empty">{{getMyLanguage("errors","errors.password.empty")}}</span>
                                    <span v-if="errorsForm.password.invalid">{{getMyLanguage("errors","errors.password.invalid")}}</span>
                                </div>

                                <div class="accepts">
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptTerms" class="form-check" v-model="accept.terms">
                                        <label for="acceptTerms" class="form-check-label">
                                            <small>{{ getMyLanguage("activate","activate.acceptTerms") }}</small>
                                            <a :href='"https://dnetoken.com/" + flagSelected + "/terms-and-conditions"' target="_blank" class="lnk-terms-group-check">{{ getMyLanguage("activate", "activate.acceptTerms.lnk") }}</a>
                                        </label>
                                    </div>
                                    
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                                        <label for="acceptNotify" class="form-check-label"><small>{{ getMyLanguage("activate","activate.acceptNotify") }}</small></label>
                                    </div>
                                    
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                                        <label for="acceptGDPR" class="form-check-label"><small>{{ getMyLanguage("activate","activate.acceptGdpr") }}</small></label>
                                    </div>
                                </div>

                                <div class="btn-primary btn-disable" id="btnRegister" v-if="!accept.readAccept || !accept.terms || !accept.notify || !accept.gdpr">{{ getMyLanguage("activate","activate.btn") }}</div>
                                <div class="btn-primary" id="btnRegister" v-if="accept.readAccept && accept.terms && accept.notify && accept.gdpr && !validating" @click="validateForm()">{{ getMyLanguage("activate","activate.btn") }}</div>

                                <div class="btn-primary btn-loading" v-if="validating"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import apiSales from '@/config/apiSales.js'
    import apiPlatform from '@/config/apiPlatform.js'

    export default {
        data () {
            return {
                errorsGet: {
                    dataInvite: false
                },
                
                dataActivate: [],
                load: true,

                accept: {
                    readAccept: false,
                    terms: false,
                    notify: false,
                    gdpr: false
                },

                validating: false,
                showPass: false,
                urlContract: '',
                newRecruit: {},

                errorsForm: {
                    fullName: {empty: false, incomplete: false},
                    email: {empty: false, invalid: false, inUse: false},
                    country: {empty: false},
                    phoneNumber: {empty: false, invalid: false, inUse: false},
                    password: {empty: false, invalid: false}
                },

                dataCountry: [],
                flagSelected: {},
                dataLanguage: [],
                listCountryStatus: false,
                selectedCountry: {},

                selectedCountryDDI: {
                    changeMask: false,
                    phoneMask: '?',
                    countrySelected: false,
                    flag: ''
                },
            }
        },
        
        async mounted () {           
            await apiSales.get('/api/v1/agent/invite/' + this.$route.params.registerId)
            .then(response => {
                this.dataInvite = response.data
                this.registerId = response.data.id;

                this.newRecruit.accountProfileId = this.dataInvite.accountProfileId
                this.newRecruit.regionId = this.dataInvite.regionId
                this.newRecruit.agentTypeId = this.dataInvite.agentTypeId

                this.generateContract(this.dataInvite.accountProfileId);
                
            })
            .catch(error => {
                this.errorsGet.dataInvite = error.response.data

                if (error.response.status === 404) {
                    window.location.href = "/" + this.flagSelected + "/404"
                }
            })            

            const resumeLanguage = await apiPlatform.get('api/v1/platform/language/dne_international_sales')
            this.dataLanguage = resumeLanguage.data.filter(lang => lang.code === this.flagSelected)[0]

            setTimeout(() => {
                this.newRecruit.languageId = this.dataLanguage.id
            }, 3000);

            const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data

            this.startMaskPhone()
            this.showContent = true
            

            setTimeout(() => {
                // this.newRecruit.inviteId = this.dataInvite.id
                this.load = false
            }, 500);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            generateContract(value){
                const idInvite = value
                this.urlContract = "/en/agent/invite-contract/" + idInvite
                // this.urlContract = `/${this.flagSelected}/agent/invite-contract/${idInvite}`
            },

            startMaskPhone(){
                // PUXAR PAIS DO SELLER AUTOMATICAMENTE
                this.selectedCountryDDI.phoneMask = '### ### ###'
                this.selectedCountryDDI.flag = "58bfc699-7e06-4362-3445-c598e9232900"
                this.selectedCountryDDI.ddi = "351"
                this.selectedCountryDDI.countrySelected = true
                this.selectedCountryDDI.active = 'active'
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    const maskPhone = this.selectedCountryDDI.phoneMask
                    
                    this.selectedCountryDDI.phoneMask = maskPhone.replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#")

                    this.selectedCountryDDI.changeMask = true
                }, 1000);
            },

            dropListCountry(status){
                this.listCountryStatus = status
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("residenceCountry");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            validateForm(){
                if ( this.newRecruit.fullName === undefined || this.newRecruit.fullName === "" ){
                    this.errorsForm.fullName.empty = true
                } else if ( this.newRecruit.fullName != undefined || this.newRecruit.fullName != "" ){
                    let fullName = this.newRecruit.fullName
                    fullName = fullName.split(' ').length;
                    if ( fullName < 2 ){
                        this.errorsForm.fullName.incomplete = true
                    } else{
                        this.errorsForm.fullName.incomplete = false
                    }
                } else{
                    this.errorsForm.fullName.empty = false
                }


                // E-MAIL
                if ( this.newRecruit.email === undefined || this.newRecruit.email === "" ){
                    this.errorsForm.email.empty = true
                } else{
                    this.errorsForm.email.empty = false
                }

                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (regex.test(this.newRecruit.email)) {
                    this.errorsForm.email.invalid = false
                } else {
                    this.errorsForm.email.invalid = true
                }

                // COUNTRY ID
                if ( this.newRecruit.countryId === undefined || this.newRecruit.countryId === "" ){
                    this.errorsForm.country.empty = true
                } else{
                    this.errorsForm.country.empty = false
                }
                
                // PHONE NUMBER
                if ( this.newRecruit.phoneNumber === undefined || this.newRecruit.phoneNumber === "" ){
                    this.errorsForm.phoneNumber.empty = true
                } else{
                    this.errorsForm.phoneNumber.empty = false
                }

                // PASSWORD
                if ( this.newRecruit.password === undefined || this.newRecruit.password === "" ){
                    this.errorsForm.password.empty = true
                } else{
                    this.errorsForm.password.empty = false
                }

                // ERRORS
                if ( !this.errorsForm.fullName.empty && !this.errorsForm.fullName.incomplete && !this.errorsForm.email.empty && !this.errorsForm.email.invalid && !this.errorsForm.email.inUse && !this.errorsForm.country.empty && !this.errorsForm.phoneNumber.empty && !this.errorsForm.phoneNumber.invalid && !this.errorsForm.phoneNumber.inUse ){
                    this.validating = true

                    setTimeout(() => {
                        this.register()
                    }, 500);
                } else{
                    this.validating = false
                }
            },

            register(){
                const register = this.newRecruit;

                apiSales.post('/api/v1/agent/invite/' + this.$route.params.registerId, register)
                .then(response => {
                    const responseData = response.data.accessToken

                    localStorage.setItem('accessToken',responseData);
                    window.location.href =  this.flagSelected + '/dashboard/'


                    // this.registerId = response.data.id;
                    // const languageRedirect = String(window.location.pathname).split("/")[1];
                    // window.location.href = '/' + languageRedirect + '/agent/invite/success/'
                })
                .catch(error => {

                    this.errorsGet.dataAccount = error.response.data
                    this.errorsGet.dataAccount = JSON.stringify(error.response.status)

                    if ( this.errorsGet.dataAccount == 401 ){
                        this.errorsForm.password.invalid = true
                    } else{
                        this.validating = false
                        this.errorMessage = error.message;
                        console.error("There was an error!", error);
                        console.log(error.response.data)
    
                        this.$toast.error(error.response.data);
    
                        setTimeout(this.$toast.clear, 5000)
                    }

                    this.validating = false
                })
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-invite.scss" lang="scss" scoped />