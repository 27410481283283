<template>
    <div class="customers">
        <Header />

        <div class="content">

            <h3 class="title-secondary" v-if="!filter">
                {{ getMyLanguage("customers", "customers.title") }}
                <small v-if="nameCustomersFilter">{{nameCustomersFilter}}</small>
            </h3>

            <div class="box-error" v-if="errorsGet.dataCustomers != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataCustomers}}
                </div>
            </div>

            <div v-if="!errorsGet.dataCustomers">

                <div class="load" v-if="lengthCustomers === 'load'"></div>

                <div class="search-bar" v-if="lengthCustomers === true && !filter">
                    <input type="text" class="form form-search" id="searchCustomer" v-on:keyup="filterList" :placeholder='getMyLanguage("customers", "customers.search")'>
                    <div class="btn-filter" @click="filter = true"></div>
                    <div class="btn-back" v-if="showBack" onclick="history.back()"></div>
                </div>
                
                <h3 class="title-secondary" v-if="filter">{{ getMyLanguage("filter","filter.title") }}</h3>
                <div class="filter box" v-if="filter">
                    <div class="close-filter" @click="filter = false"></div>

                    <div class="filters">
                        <div class="form-group-switch">
                            <input type="checkbox" v-model="acceptWithdraw" name="" id="acceptWithdraw" class="form-switch">
                            <label for="acceptWithdraw" class="form-switch-label">
                                <small>Filter name</small>
                            </label>
                        </div>
                    </div>

                    <div class="btn-primary">Filter</div>
                </div>

                <div class="box" v-if="lengthCustomers === true">
                    <div class="scroll-horizontal">
                        <table class="list-result" id="list">
                            <thead class="header-list">
                                <tr>
                                    <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                    <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>
                                    <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                    <th>{{ getMyLanguage("list-result","purchase") }}</th>
                                    <th>{{ getMyLanguage("list-result","sales") }}</th>
                                    <th>{{ getMyLanguage("list-result","customers") }}</th>
                                    <th>{{ getMyLanguage("list-result","recruit") }}</th>
                                    <!-- <th class="type">{{ getMyLanguage("list-result","type") }}</th>
                                    <th class="temperature">{{ getMyLanguage("list-result","temperature") }}</th> -->
                                </tr>
                            </thead>

                            <tbody id="drop-list">
                                <tr v-for="(clientItem,index) in dataCustomers" :key="index" class="list">
                                    <!-- <td class="date" @click="modalLead(index)"><span>{{ dateTimeMin(clientItem.account_created_at) }}</span></td>
                                    <td class="fullName" @click="modalLead(index)"><span>{{clientItem.profile_full_name}}</span></td> -->
                                    <td class="date"><span>{{ dateTimeMin(clientItem.account_created_at) }}</span></td>
                                    <td class="fullName"><span>{{clientItem.profile_full_name}}</span></td>
                                    <td class="status tooltip" :class="clientItem.account_status">
                                        <span>{{ getMyLanguage("status", clientItem.account_status) }}</span>

                                        <div class="tooltip">{{ getMyLanguage("leads","tooltip." + clientItem.account_status) }}</div>
                                    </td>
                                    <!-- <td class="purchase" @click="modalLead(index)"> -->
                                    <td class="purchase">
                                        <span v-if="clientItem.has_purchase"><img src="~@/assets/images/icons/has-purchase.svg"></span>
                                        <small v-if="!clientItem.has_purchase">- -</small>
                                    </td>
                                    <td class="sales">
                                        <span @click="listSales(clientItem.account_id, clientItem.profile_full_name)" v-if="clientItem.is_agent">{{ getMyLanguage("leads","list.sales") }}</span>
                                        <small v-if="!clientItem.is_agent">- -</small>
                                    </td>
                                    <td class="leads">
                                        <span @click="listCustomers(clientItem.account_id, clientItem.profile_full_name)" v-if="clientItem.is_agent">{{ getMyLanguage("menu","customers") }}</span>
                                        <small v-if="!clientItem.is_agent">- -</small>
                                    </td>
                                    <td class="recruit">
                                        <span @click="openRecruit(index)" v-if='!clientItem.is_agent && clientItem.account_status === "Verified" && clientItem.account_id != dataAgent.accountId'>{{ getMyLanguage("register", "agent.label.recruit") }}</span>
                                        <small v-if="clientItem.is_agent">- -</small>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="no-results" v-if="lengthCustomers === false">
                    <p>{{ getMyLanguage("leads", "no-leads") }}</p>
                    <div class="btn-back" onclick="history.back()"></div>
                </div>
            </div>
        </div>


        <!-- MODAL LEAD -->
        <div class="modal modal-lead" v-if="showModalCustomer">
            <div @click="showModalCustomer = false, showFollowUp = false" class="close-modal"></div>
            <div class="box cont-modal">
                <div class="scroll-vertical" v-if="!dataFollowUpModalSuccess">
                    <h3 class="title-secondary">{{dataModal.profile_full_name}}</h3>

                    <div class="load" v-if="!showFollowUp"></div>

                    <div v-if="showFollowUp">
                        <label>
                            {{ getMyLanguage("register", "register.label.lead-type") }}
                            <select class="form" v-model="dataFollowUpModal.leadType">
                                <option v-for="(leadType,index) in dataLeadType" :key="index" :value="leadType.id">{{ getMyLanguage("status", leadType.value) }}</option>
                            </select>
                        </label>

                        <div class="twoColumns">
                            <label>
                                {{ getMyLanguage("register", "register.label.campaign") }}
                                <select class="form" v-model="dataFollowUpModal.campaignId">
                                    <option v-for="(campaign,index) in dataCampaign" :key="index" :value="campaign.id">{{ getMyLanguage("status", campaign.name) }}</option>
                                </select>
                            </label>

                            <label>
                                <span>{{ getMyLanguage("register", "register.label.date-return") }}</span>
                                <input type="date" class="form" v-model="dataFollowUpModal.returnAt">
                            </label>
                        </div>

                        <div class="level-lead">
                            <label>
                                {{ getMyLanguage("register", "register.label.lead-level") }}
                            </label>
                            
                            <div class="level-lead-content">
                                <div class="form-group-radio" :class="temperature.value" v-for="(temperature,index) in dataLeadTemperature" :key="index">
                                    <input type="radio" :value="dataFollowUpModal.leadTemperature" name="level-lead" :id="temperature.id" class="form-radio" @click='dataFollowUpModal.leadTemperature = temperature.id' :checked="(dataFollowUpModal.leadTemperature == temperature.id)" >
                                    <label :for="temperature.id" class="form-radio-label">
                                        <small>{{ getMyLanguage("status", temperature.value) }}</small>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <label>
                            {{ getMyLanguage("register", "register.label.observations") }}
                            <div class="textArea">
                                <textarea class="form" v-model="dataFollowUpModal.observations" spellcheck="false" autocapitalize="off"></textarea>
                            </div>
                        </label>

                        <div class="item-buttons" v-if="!stepRegister.load">
                            <div @click="saveFollowUp()" class="btn-primary">{{ getMyLanguage("register", "register.btn-save") }}</div>
                        </div>

                        <div class="btn-primary btn-loading" v-if="stepRegister.load"></div>
                    </div>
                </div>


                <div v-if="dataFollowUpModalSuccess">
                    <h3 class="title-secondary">{{dataFollowUpModal.account.name}} {{dataFollowUpModal.account.surname}}</h3>

                    <div class="success">
                        <h2>{{ getMyLanguage("register", "success.txt") }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="showModalCustomer"></div>
        

        <!-- MODAL RECRUIT -->
        <div class="modal modal-recruit" v-if="showModalRecruit">
            <div @click="this.showModalRecruit = false" class="close-modal"></div>
            <div class="box cont-modal">
                <div class="scroll-vertical">
                    <h3 class="title-secondary" v-if="!dataRecruitModalSuccess">{{ getMyLanguage("register", "agent.recruit.title") }}</h3>

                    <div class="load" v-if="loadingRecruit"><span></span></div>

                    <!-- HAS INVITE -->
                    <div class="box has-invite" v-if="!loadingRecruit && !dataRecruitModalSuccess">
                        <h4 class="title-secondary">{{dataModal.profile_full_name}}</h4>
                        
                        <div class="twoColumns" v-if="!dataRecruit.hasInvite">
                            <label>
                                {{ getMyLanguage("register", "agent.label.salesRegion") }}
                                <select class="form" v-model="dataRecruit.regionId">
                                    <option value="72ded956-b703-477c-bf4b-1d8566618d42">Global Region</option>
                                    <option value="53d079c2-3101-4757-b605-18dd0cfe75fb">Portugal</option>
                                    <option value="c621dd7e-b647-428b-888b-934531285ad2">Portugal Norte</option>
                                </select>
                            </label>

                            <label>
                                {{ getMyLanguage("register", "agent.label.agentType") }}
                                <select class="form" v-model="dataRecruit.agentTypeId">
                                    <option value="ce930f11-b951-4382-a784-fd495b2ad487">{{ getMyLanguage("register", "agent.select.fieldSeller") }}</option>
                                </select>
                            </label>
                        </div>

                        <div class="recruitment-progress" v-if="dataRecruit.hasInvite">
                            {{getMyLanguage("register","register.recruitment-progress")}}
                        </div>

                        <div @click="sendRecruit()" expand="block" class="btn-primary" v-if="!dataRecruit.hasInvite">{{ getMyLanguage("register", "agent.label.recruit") }}</div>
                        <!-- <div @click="sendRecruit()" expand="block" class="btn-primary" v-if="dataRecruit.hasInvite">{{ getMyLanguage("register", "agent.label.resend-recruitment") }}</div> -->
                        <div class="btn-primary btn-loading" v-if="stepRegister.load"></div>
                        

                        <div class="share-has-invite" v-if="dataRecruit.hasInvite">
                            <div class="has-invite-msg">{{ getMyLanguage("register","register.share.msg") }}</div>

                            <label>
                                <!-- {{ getMyLanguage("register", "register.label.share") }} -->
                                <div class="share" @click="copyShare(dataRecruit.inviteLink)"><span>{{dataRecruit.inviteLink}}</span></div>
                            </label>
                        </div>
                    </div>


                    <!-- SUCCESS -->
                    <div v-if="dataRecruitModalSuccess">
                        <h3 class="title-secondary">{{ getMyLanguage("register", "agent.successfull.title") }}</h3>

                        <div class="success-recruit">
                            <div class="resume-recruit">
                                <p>{{ getMyLanguage("register", "agent.successfull.msg01") }} <strong>{{dataRecruit.fullName}}</strong> {{ getMyLanguage("register", "agent.successfull.msg02") }} <strong>{{ getMyLanguage("register", "agent.select.fieldSeller") }}</strong> {{ getMyLanguage("register", "agent.successfull.msg03") }} <strong>{{dataRecruit.regionName}}</strong>.</p>
                                <p>{{ getMyLanguage("register", "agent.successfull.msg04") }}</p>
                                
                            </div>
                            
                            <div class="success-share">
                                <p>{{ getMyLanguage("register","register.share.msg") }}</p>
                                <div class="share" @click="copyShare(dataRecruit.inviteLink)"><span>{{dataRecruit.inviteLink}}</span></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="showModalRecruit"></div>
    </div>
</template>

<script>
    // const $ = require('jquery')
    // window.$ = $

    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';

    import apiSales from '@/config/apiSales.js'
    import apiInternational from '@/config/apiInternational.js'
    import apiPlatform from '@/config/apiPlatform.js'
    import moment from 'moment';
    import {mask} from 'vue-the-mask'

    export default {
        components: {
            Header
        },

        directives: {mask},

        data () {
            return {
                dataAgent: [],
                dataCustomers: [],
                dataSales: [],

                lengthCustomers: "load",

                nameCustomersFilter: false,
                showBack: false,

                flagSelected: {},
                filter: false,

                filtersParam: {},
                
                // showModalSale: false,
                // showSales: false,

                showModalCustomer: false,
                dataModal: [],
                dataFollowUpModal: [],

                stepRegister: {
                    load: false
                },

                showFollowUp: false,

                dataFollowUpModalSuccess: false,

                showModalRecruit: false,
                loadingRecruit: true,
                dataRecruit: {},
                dataRecruitModalSuccess: false,

                errorsGet: {
                    dataCustomers: false
                }
            }
        },

        async mounted () {
            const urlParams = new URLSearchParams(window.location.search);
            const accountIdParams = urlParams.get('account_id');
            const nameLead = urlParams.get('name')

            if ( nameLead == null ){
                this.nameCustomersFilter = localStorage.getItem("agentName")
            } else {
                this.nameCustomersFilter = nameLead
                this.showBack = true
            }

            // LEADS
            if ( accountIdParams == null || accountIdParams == '' ){
                apiSales.get('/api/v1/sales/profiles')
                .then(response => {
                    this.dataCustomers = response.data

                    if ( this.dataCustomers.length > 0 ){
                        this.lengthCustomers = true
                    } else{
                        this.lengthCustomers = false
                    }
                    
                    // setTimeout(() => {
                    //     this.fixedHeaderList()
                    // }, 500);

                })
                .catch(error => {
                    this.errorsGet.dataCustomers = error.response.status

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }
                })
            } else{
                apiSales.get('/api/v1/sales/profiles?account_id=' + accountIdParams)
                .then(response => {
                    this.dataCustomers = response.data

                    if ( this.dataCustomers.length > 0 ){
                        this.lengthCustomers = true
                    } else{
                        this.lengthCustomers = false
                    }
                })
                .catch(error => {
                    this.errorsGet.dataCustomers = error.response.status
                })
            }


            // AGENT
            apiSales.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataAgent = response.data
            })
            .catch(error => {
                this.openToastError(error);
            })


            // PHASE
            apiInternational.get('/api/v2/ico/phase')
            .then(response => {
                this.dataCampaign = response.data
            })
            .catch(error => {
                this.openToastError(error);
            })


            // TEMPERATURE
            apiPlatform.get('/api/v1/public/attributes/codes/lead_temperature')
            .then(response => {
                this.dataLeadTemperature = response.data
            })
            .catch(error => {
                this.openToastError(error);
            })


            // LEAD TYPE
            apiPlatform.get('/api/v1/public/attributes/codes/lead_type')
            .then(response => {
                this.dataLeadType = response.data
            })
            .catch(error => {
                this.openToastError(error);
            })
            

            // LEAD STATUS
            // apiPlatform.get('/api/v1/public/attributes/codes/lead_status')
            // .then(response => {
            //     this.dataCustomerstatus = response.data
            // })
            // .catch(error => {
            //     this.openToastError(error);
            // })
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY-MM-DD' : 'DD-MM-YYYY';
                return moment(value).format(format);
            },

            dateTimeMin(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchCustomer");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            editLead(id){
                const idLead = id
                const languageRedirect = String(window.location.pathname).split("/")[1];
                window.location.href = '/' + languageRedirect + '/leads/' + idLead
            },

            newSale(id){
                const idLead = id
                const languageRedirect = String(window.location.pathname).split("/")[1];
                window.location.href = '/' + languageRedirect + '/leads/sale/' + idLead
            },

            async modalLead(id){
                this.dataModal = this.dataCustomers[id]

                const resumeFollowUp = await apiSales.get('/api/v1/sales/follow_up/' + this.dataModal.account_id)
                this.dataFollowUpModal = resumeFollowUp.data

                // this.dataFollowUpModal.returnAt = this.dateTime(this.dataFollowUpModal.returnAt)
                this.dataFollowUpModalSuccess = false
                this.stepRegister.load = false

                this.showModalCustomer = true

                setTimeout(() => {
                    this.showFollowUp = true
                }, 500);
            },            
            
            // async modalSale(id){
            //     this.showModalSale = true

            //     const resumeSales = await apiSales.get('/api/v1/sales/commissions?account_id=' + id)
            //     this.dataSales = resumeSales.data

            //     console.log(id)

            //     setTimeout(() => {
            //         this.showSales = true
            //     }, 500);
            // },

            listCustomers(value, name){
                window.location.href = '/' + this.flagSelected + '/agents/customers?account_id=' + value + "&name=" + name
            },
            
            listSales(value, name){
                window.location.href = '/' + this.flagSelected + '/sales?account_id=' + value + "&name=" + name
            },

            async openRecruit(id){
                this.loadingRecruit = true
                this.dataModal = this.dataCustomers[id]
                
                apiSales.get('/api/v1/agent/recruit/' + this.dataModal.account_id)
                .then(response => {
                    this.dataRecruit = response.data

                    if (!this.dataRecruit.hasInvite){
                        this.dataRecruit.inviteId = "00000000-0000-0000-0000-000000000000"
                        this.dataRecruit.regionId = "72ded956-b703-477c-bf4b-1d8566618d42"
                        this.dataRecruit.agentTypeId = "ce930f11-b951-4382-a784-fd495b2ad487"
                    }

                    setTimeout(() => {
                        this.loadingRecruit = false
                    }, 200);
                })
                .catch(error => {
                    
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log(error.response.data)

                    this.stepRegister.load = false

                    this.$toast.error(error.response.data);

                    setTimeout(this.$toast.clear, 5000)

                    setTimeout(() => {
                        this.loadingRecruit = false
                    }, 200);
                })
                
                this.showModalCustomer = false
                this.showModalRecruit = true
            },

            async copyShare(urlShare) {
                try {
                await navigator.clipboard.writeText(urlShare);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                this.$toast.success(this.getMyLanguage("leads","message-toast.copied"));
                setTimeout(this.$toast.clear, 6000)
            },            
            
            async openToastNocopy() {
                this.$toast.success(this.getMyLanguage("leads","message-toast.noCopied"));
                setTimeout(this.$toast.clear, 6000)
            },

            saveFollowUp(){
                this.stepRegister.load = true

                apiSales.post("/api/v1/sales/lead/" + this.dataFollowUpModal.account.accountId, this.dataFollowUpModal.followUp)
                .then(response => {
                    this.registerId = response.data.id;
                    this.dataFollowUpModalSuccess = true

                    setTimeout(() => {
                        this.$router.go()
                    }, 2000);
                })
                .catch(error => {
                    this.errorMessage = error.message;

                    this.stepRegister.load = false
                    this.dataFollowUpModalSuccess = false

                    this.openToastError(error);
                })
            },

            sendRecruit(){
                this.stepRegister.load = false
                const recruit = this.dataRecruit

                apiSales.post("/api/v1/agent/recruit/" + this.dataModal.account_id, recruit)
                .then(response => {
                    this.registerId = response.data.id;
                    this.dataRecruit = response.data
                    this.dataRecruitModalSuccess = true
                    this.stepRegister.load = false

                })
                .catch(error => {
                    this.errorMessage = error.message;

                    this.stepRegister.load = false

                    this.openToastError(error);
                })
            },

            async openToastError() {
                this.$toast.error(this.getMyLanguage("box-error","msg-error"));
                setTimeout(this.$toast.clear, 6000)
            },

            // fixedHeaderList(){
            //     const listHeader = $('.list-result');
            //     const positionList = $('.list-result').offset().top
            //     const positionAction = positionList - 81

            //     $(window).scroll(function () {
            //         if ($(this).scrollTop() > positionAction) {
            //             listHeader.addClass("headerListFixed");
            //         } else {
            //             listHeader.removeClass("headerListFixed");
            //         }
            //     });
            // }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-customers.scss" lang="scss" scoped />