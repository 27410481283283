<template>
    <div class="select-language">
        <div class="selected-lang">
            <div class="close-select" v-if="selectStatus" @click="selectStatus = false"></div>
            <div class="flag" @click="selectStatus = true">
                <div class="load-flag" v-if="!flagShow"></div>
                <img v-if="flagShow" :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + flagSelected + "/flags"'>
            </div>
        </div>
        <div class="flags-language" v-if="selectStatus">
            <div class="close-select" v-if="selectStatus" @click="selectStatus = false"></div>
            <h4 class="title-secondary">{{getMyLanguage("SelectLanguage","title")}}</h4>

            <div class="list-languages">
                <div class="flag" v-for="(langs,index) in $store.state.allLanguages" :key="index" @click="changeLanguage(langs.code, langs.flag)">
                    <img :src='"https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/" + langs.flag + "/flags"'>
                    <span>{{langs.name}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="overlay" v-if="selectStatus" @click="selectStatus = false"></div>
</template>


<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    export default{
        data: function () {
            return {
                flagSelected: {},
                selectStatus: false,
                flagShow: false
            }
        },

        methods: {
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
            
            changeLanguage(parLanguage, parFlag){
                this.flagShow = false
                this.selectStatus = false

                localStorage.setItem('codeLanguage',parLanguage);
                localStorage.setItem('flagLanguage',parFlag);

                var url = window.location.pathname;
                var newUrl = url.replace(/\/[a-z]{2}\//, '/');
                var isHome = String(window.location.pathname).split("/")

                if ( isHome.length > 2 ){
                    newUrl = '/' + parLanguage + newUrl;
                } else {
                    newUrl = '/' + parLanguage
                }
                
                history.pushState(null, null, newUrl);

                this.flagSelected = parFlag
                this.$store.commit('preLanguage',parLanguage)
                this.$store.commit('preLanguageFlag',parFlag)

                setTimeout(() => {
                    this.flagShow = true
                }, 500);
            },
        },
        async mounted () {
            this.flagSelected = localStorage.getItem('flagLanguage')
            this.flagShow = true

            setTimeout(() => {
                if ( !localStorage.getItem("flagLanguage") ){
                    const languageURL = String(window.location.pathname).split("/")[1];
                    const filterFlag = this.$store.state.allLanguages.filter(langs => langs.code == languageURL)[0].flag
                    
                    if ( localStorage.getItem("flagLanguage") != filterFlag ){
                        this.changeLanguage(languageURL, filterFlag)
                    }
                }
            }, 1000);
        }
    }
</script>


<style src="@/assets/scss/_selectLanguage.scss" lang="scss" scoped />