<template>
    <div class="report">
        <Header />

        <div class="content">
            <h3 class="title-secondary">{{ getMyLanguage("report", "report.title") }}</h3>
            <div class="load load-center" v-if="!showContent"></div>

            <div class="box-error" v-if="errorsGet.dataReports != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                <div class="cod-error">
                    Cód.: {{errorsGet.dataReports}}
                </div>
            </div>
                

            <div v-if="showContent && !errorsGet.dataReports">
                <div class="search-bar" v-if="dataReports.length > 0 &&  filter === false">
                    <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("agents","agents.search")'>
                    <!-- <div class="btn-filter" @click="filter = true"></div> -->
                    <div class="btn-add" @click='openReport("00000000-0000-0000-0000-000000000000")' v-if='dataAgent.agentTypeCode != "master_franchising"'>{{getMyLanguage("report","btn-new-report")}}</div>
                </div>

                <h3 class="title-secondary" v-if="filter === true">{{ getMyLanguage("report","report.title") }}</h3>
                <div class="filter box" v-if="filter === true">
                    <div class="close-filter" @click="filter = false"></div>
                    
                    <div class="filters">
                        <div class="form-group-switch">
                            <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                            <label for="stage" class="form-switch-label">
                                <small>Filter name</small>
                            </label>
                        </div>
                    </div>

                    <div class="btn-primary">Filter</div>
                </div>

                <div class="box" v-if="lengthReport">
                    <div class="scroll-horizontal">
                        <table class="list-result">
                            <thead class="header-list">
                                <tr>
                                    <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>
                                    <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                    <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                    <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                </tr>
                            </thead>

                            <tbody id="drop-list">
                                <tr v-for="(reportList,index) in dataReports" :key="index" @click="openReport(reportList.agentReportId)" class="list">
                                    <td class="fullName text-left"><span>{{reportList.agentName}}</span></td>
                                    <td class="date"><span>{{dateTimeMin(reportList.dateStart)}}</span></td>
                                    <td class="date"><span>{{dateTimeMin(reportList.dateEnd)}}</span></td>
                                    <td class="status" :class="reportList.agentReportStatus"><span>{{ getMyLanguage("status","report." + reportList.agentReportStatus) }}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="no-results" v-if="!lengthReport">
                    <p>{{ getMyLanguage("report", "no-report") }}</p>
                    <div class="btn-add" v-if='dataAgent.agentTypeCode != "master_franchising" && !lengthReport' @click='openReport("00000000-0000-0000-0000-000000000000")'>{{getMyLanguage("report","btn-new-report")}}</div>
                </div>
            </div>
        </div>
            

        <!-- MODAL -->
        <div class="modal" v-if="showModal">
            <div @click="closeUpdate()" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll-vertical">

                    <h3>{{getMyLanguage("report","btn-new-report")}}</h3>
                    <!-- <h3>{{getMyLanguage("report","report.reported-by")}} {{dataModal.agentName}}</h3> -->
                    
                    <div class="form-report">
                        <div class="date-report">
                            <label>
                                {{getMyLanguage("report","date-start")}}
                                <input type="date" class="form" v-model="dataModal.dateStart" v-if='dataModal.agentReportStatus === "newReport"'>
                                <h4 v-if='dataModal.agentReportStatus != "newReport"'>{{dateTimeFull(dataModal.dateStart)}}</h4>
                            </label>

                            <label>
                                {{getMyLanguage("report","date-end")}}
                                <input type="date" class="form" v-model="dataModal.dateEnd" v-if='dataModal.agentReportStatus === "newReport"'>
                                <h4 v-if='dataModal.agentReportStatus != "newReport"'>{{dateTimeFull(dataModal.dateEnd)}}</h4>
                            </label>
                        </div>

                        <div class="report">
                            <h4>{{getMyLanguage("report","report.subtitle")}}</h4>

                            <div v-if='dataModal.agentReportStatus === "newReport"'>
                                <textarea class="form" v-model="dataModal.report"></textarea>
                            </div>

                            <div class="txt" v-if='dataModal.agentReportStatus != "newReport"'>
                                {{dataModal.report}}

                                <p v-if='dataModal.agentReportStatus === "Viewed"'><small>{{getMyLanguage("report","report.viewed-by")}} {{dataModal.updatedBy}} {{getMyLanguage("report","report.viewed-in")}} {{dateTimeFull(dataModal.updatedAt)}}</small></p>
                            </div>
                        </div>
                        
                        <div class="reply" v-if='(dataModal.reply != null && dataModal.reply != "") || dataAgent.agentTypeCode === "master_franchising"'>
                            <h4>{{getMyLanguage("report","reply.subtitle")}}</h4>
                            <textarea class="form" v-model="dataModal.reply" v-if='dataAgent.agentTypeCode === "master_franchising"'></textarea>
                            
                            <p v-if='(dataModal.agentReportStatus === "Replyed" || dataModal.agentReportStatus === "Finished") && dataAgent.agentTypeCode != "master_franchising"'>{{dataModal.reply}}</p>
                            <p v-if='(dataModal.agentReportStatus === "Replyed" || dataModal.agentReportStatus === "Finished") && dataAgent.agentTypeCode != "master_franchising"'><small>{{getMyLanguage("report","report.replied by")}} {{dataModal.updatedBy}} {{getMyLanguage("report","report.viewed-in")}} {{dateTimeFull(dataModal.updatedAt)}}</small></p>
                        </div>
                        
                        <div class="bts">
                            <div class="btn-primary" v-if='dataModal.agentReportStatus === "newReport"' @click="send()">{{getMyLanguage("report","btn-send-report")}}</div>
                            <div class="btn-primary" v-if='dataModal.agentReportStatus != "newReport" && dataAgent.agentTypeCode === "master_franchising"' @click="send()">{{getMyLanguage("report","btn-send-reply")}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="showModal"></div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import Header from '@/components/Header.vue';

    import moment from 'moment';
    import apiSales from '@/config/apiSales.js'

    export default {
        components: {
            Header
        },

        data () {
            return {
                flagSelected: {},
                lengthReport: 'load',
                showContent: false,
                showModal: false,

                dataAgent: [],

                dataReports: {
                    modal: false,
                    dateStart: '',
                    dateEnd: '',
                    report: ''
                },

                dataModal: {},

                errorsGet: {
                    dataReports: false,
                    dataModal: false
                },

                errorsPost: {
                    report: false,
                    reply: false
                },

                filter: false,
                filtersParam: {},
                filtersCheck: {},
            }
        },
        
        async mounted () {
            await apiSales.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataAgent = response.data
            })
            .catch(error => {
                // this.errorsGet.dataAgent = JSON.stringify(error.response.status)
                
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }
                
                if (error.response.status == 409) {
                    window.location.href = '/' + this.flagSelected + '/dashboard/agent-sign'
                }
                
                if (error.response.status == 403) {
                    window.location.href = '/' + this.flagSelected + '/page403'
                }

                // else{
                //     window.location.href = '/' + this.flagSelected + '/page403'
                // }
            })

            this.reports()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            dateTimeMin(value) {
                return moment(value).format('DD/MM');
            },

            dateTimeFull(value) {
                return moment(value).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            async reports(){
                await apiSales.get('/api/v1/agent/reports')
                .then(response => {
                    this.dataReports = response.data
                    
                    setTimeout(() => {
                        this.showContent = true
                    }, 500);
                })
                .catch(error => {
                    this.errorsGet.dataReports = error

                    if (error.response.status === 401) {
                        localStorage.removeItem('accessToken');
                        window.location.href = '/'
                    }

                    setTimeout(() => {
                        this.showContent = true
                    }, 500);
                })
                
                if ( this.dataReports.length > 0 ){
                    this.lengthReport = true
                } else{
                    this.lengthReport = false
                }
            },

            async openReport(value){
                
                if ( value === "00000000-0000-0000-0000-000000000000"){
                    this.dataModal.agentReportId = value
                    this.dataModal.report = this.getMyLanguage('report','report.msg.newreport')
                    this.dataModal.dateStart = this.dateTime(this.dataModal.dateStart)
                    this.dataModal.dateEnd = this.dateTime(this.dataModal.dateEnd)
                    this.dataModal.agentReportStatus = "newReport"
                    this.showModal = true
                } else {
                    await apiSales.get('/api/v1/agent/reports/' + value)
                    .then(response => {
                        this.dataModal = response.data
                        this.showModal = true
                    })
                    .catch(error => {
                        this.errorsGet.dataModal = error
                    })
                }
            },

            async toastSuccess() {
                this.$toast.success(this.getMyLanguage("activate","title.success"));
            },

            send(){
                apiSales.post("/api/v1/agent/reports/" + this.dataModal.agentReportId, this.dataModal)
                .then(response => {
                    console.log(response)
                    console.clear()
                    this.toastSuccess()
                    this.reports()
                    this.showModal = false
                })
                .catch(error => {
                    // this.errorsPost.report = JSON.stringify(error.response.status)
                    this.errorsPost.report = error
                })
            },

            closeUpdate(){
                this.reports()
                this.dataModal.reply = null
                this.showModal = false
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-report.scss" lang="scss" scoped />