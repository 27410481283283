<template>
    <div class="manual">
        <Header />

        <div class="content" v-if="showContent">
            <h3 class="title-secondary">{{ getMyLanguage("manuals", "manuals.title") }}</h3>

            <div class="manuals">
                <div class="box box-download">
                    <div class="description">
                        <h3>Whitepaper <small>(PDF) 30/04/24</small></h3>
                        <a :href='"https://cdn.dneplatform.com/docs/WhitePaper-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>

                <div class="box box-download">
                    <div class="description">
                        <h3>{{ getMyLanguage("manuals","manuals.ecosystem-presentation-seller") }} <small>(PDF) 30/04/24</small></h3>
                        <a :href='"https://cdn.dneplatform.com/docs/marketing/DNE-Presentation-Seller-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>

                <div class="box box-download">
                    <div class="description">
                        <h3>ICO > {{ getMyLanguage("manuals","manuals.ecosystem-presentation-seller") }} <small>(PDF) 30/04/24</small></h3>
                        <a :href='"https://cdn.dneplatform.com/docs/marketing/DNE-Presentation-Seller-ico-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>

                <div class="box box-download">
                    <div class="description">
                        <h3>{{ getMyLanguage("manuals","manuals.ecosystem-presentation") }} <small>(PDF) 30/04/24</small></h3>
                        <a :href='"https://cdn.dneplatform.com/docs/marketing/DNE-Ecosystem-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>

                <div class="box box-download">
                    <div class="description">
                        <h3>{{ getMyLanguage("manuals","manuals.ecosystem-presentation") }} <small>(Youtube) 30/04/24</small></h3>
                        <div @click="modalvideo = true" class="btn-primary btn-link">{{ getMyLanguage("manuals","manuals.link") }}</div>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>

                <div class="box box-download">
                    <div class="description">
                        <h3>{{ getMyLanguage("manuals","manuals.flowchart.POS") }} <small>(JPG) 12/09/22</small></h3>
                        <a :href='"/fluxograma_DNE-POS.png?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>
                
                <div class="box box-download">
                    <div class="description">
                        <h3>{{ getMyLanguage("manuals","manuals.flowchart.resale-boxes") }} <small>(JPG) 12/09/22</small></h3>
                        <a :href='"/fluxograma_DNE-Revenda-Boxes.png?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>
                
                <div class="box box-download">
                    <div class="description">
                        <h3>{{ getMyLanguage("manuals","manuals.flowchart.private-sale") }} <small>(JPG) 12/09/22</small></h3>
                        <a :href='"/fluxograma_DNE-Venda-Privada.png?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="btn-primary btn-download">{{ getMyLanguage("manuals","manuals.download") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>
                
                <div class="box box-download">
                    <div class="description">
                        <h3>{{ getMyLanguage("manuals","manuals.stellar.dne") }} <small>(LINK) 16/09/22</small></h3>
                        <a href="https://stellar.expert/explorer/public/asset/DNE-GD62YP66LOTG6WBQCTARX3GLCNNLFKOISNS46UUTN5FUO3GFHTTUEEWV" target="_blank" class="btn-primary btn-link">{{ getMyLanguage("manuals","manuals.link") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>
                
                <div class="box box-download">
                    <div class="description">
                        <h3>FINMA <small>(LINK) 27/10/23</small></h3>
                        <a href="https://www.finma.ch/en/authorisation/self-regulatory-organisations-sros/sro-member-search" target="_blank" class="btn-primary btn-link">{{ getMyLanguage("manuals","manuals.link") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>
                
                <div class="box box-download">
                    <div class="description">
                        <h3>SO-FIT <small>(LINK) 27/10/23</small></h3>
                        <a href="https://so-fit.ch/recherche/" target="_blank" class="btn-primary btn-link">{{ getMyLanguage("manuals","manuals.link") }}</a>
                    </div>
                    <div class="indication">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate laborum quia eius eaque sed! Dicta sunt molestiae quia odio laborum praesentium.</div>
                </div>
            </div>
        </div>


        <!-- MODAL VIDEO -->
        <div class="modal modal-video" v-if="modalvideo">
            <div @click="modalvideo = false" class="close-modal"></div>
            <div class="box cont-modal video-container">
                <iframe src="https://www.youtube.com/embed/j9Bkzz8bYBs?rel=0&controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="overlayModal" v-if="modalvideo"></div>

    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import apiSales from '@/config/apiSales.js';

    import Header from '@/components/Header.vue';

    export default {
        components: {
            Header
        },

        data () {
            return {
                flagSelected: {},
                showContent: false,
                dataProfile: [],
                modalvideo: false
            }
        },

        async mounted () {
            await apiSales.get('/api/v1/platform/account/agent')
            .then(response => {
                this.dataProfile = response.data
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/'
                }
            })

            setTimeout(() => {
                this.showContent = true
            }, 500);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-manuals.scss" lang="scss" scoped />